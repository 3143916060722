import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Premise } from "../_models/premise";
import { MessageResponse } from "../_models/responses";
import { PremiseAssociation } from '../_models/premise_association';
import { environment } from "../../environments/environment";
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class PremiseService {
    private premise$ = new BehaviorSubject<any>({});
    selectedPremises$ = this.premise$.asObservable();

    private association$ = new BehaviorSubject<any>({});
    selectedAssociation$ = this.premise$.asObservable();

    constructor(private http: HttpClient) {

    }

    setAssociation(association: any) {
        this.association$.next(association);
        localStorage.setItem('association', JSON.stringify(association));
    }

    setPremise(premise: any) {
        this.premise$.next(premise);
        localStorage.setItem('premise', JSON.stringify(premise));
    }

    createPremises(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<MessageResponse>(`${environment.apiUrl}/api/premise`, request, { headers : header});
    }

    createPremiseAssociation(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<MessageResponse>(`${environment.apiUrl}/api/premise/associate`, request, { headers: header });
    }


    updatePremiseAssociation(id: number, request: any) {
        return this.http.put<MessageResponse>(`${environment.apiUrl}/api/premise/associate/` + id, request);
    }

    deleteAssociation(id: number) {
        return this.http.delete<MessageResponse>(`${environment.apiUrl}/api/premise/associate/` + id);
    }

    fetchPremiseData(premise: number, propertyid: number) {
        return this.http.get<Premise>(`${environment.apiUrl}/api/premise/` + premise + `/property/` + propertyid);
    }

    fetchPremiseDataProtected(premise: number, propertyid: number) {
        return this.http.get<Premise>(`${environment.apiUrl}/api/premise/dp/` + premise + `/property/` + propertyid);
    }

    fetchPremiseAssociations(propertyid: number, premiseid: number) {
        return this.http.get<PremiseAssociation[]>(`${environment.apiUrl}/api/premise/associations/` + propertyid + `/` + premiseid);
    }

    fetchPremiseAssociation(id: number) {
        return this.http.get<PremiseAssociation>(`${environment.apiUrl}/api/premise/associate/` + id);
    }

    fetchUserAssociation() {
        return this.http.get<PremiseAssociation[]>(`${environment.apiUrl}/api/premise/user/associations`);
    }

}
