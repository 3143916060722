<div class="container" *ngIf="action == 'edit'">
    <h1>Edit Premises</h1>
</div>

<div class="container" *ngIf="action == 'list'">

    <div class="d-flex justify-content-between align-items-center">
      <h1 class="fs-2 fw-light mt-4">List Premises - Property</h1>

      <button (click)="onAddPremises()" class="mt-4 btn btn-primary fw-normal">Add <fa-icon [icon]="faCirclePlus"></fa-icon></button>
    </div>

    <div class="form-group has-search">
      <fa-icon class="form-control-feedback" [icon]="faSearch"></fa-icon>
      <input type="text" name="search" [(ngModel)]="searchPremise" class="form-control" placeholder="Search by premises number">
      <button (click)="goHome()" class="btn btn-info mt-4">Home <fa-icon [icon]="faHomeAlt"></fa-icon></button>
    </div>
    <div (click)="onSelectedPremises(premise)" class="premm-div border" *ngFor="let premise of property.premises | filter:searchPremise |  paginate: { itemsPerPage: 50, currentPage: p }">
      <p class="mt-1 px-1 text-center fw-bold fs-5"><fa-icon [icon]="faHashTag"></fa-icon>{{premise.unit_number}}</p>
      <p class="mt-1 px-1 text-center"><fa-icon [icon]="faBed"></fa-icon> {{premise.number_rooms}} </p>
    </div>
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
</div>

<div class="container mt-4" *ngIf="action == 'view'" id="content" #content>
  <div class="d-inline-flex">
    <h1 class="fs-2 fw-light mt-4">Premises Details</h1>
    <div class="d-inline-block mt-4 ">
      <button (click)="sendSmsUser()" class="btn btn-primary mx-2 d-print-none"><fa-icon [icon]="faSms"></fa-icon></button>
      <button (click)="sendWhatsappUser('John Brown')" class="btn btn-success mx-2 d-print-none"><fa-icon [icon]="faWhatsapp"></fa-icon></button>
      <button (click)="viewAssociations()" class="btn btn-info mx-2 d-print-none"><fa-icon [icon]="faUsersViewfinder"></fa-icon></button>
      <button (click)="printScreen()" class="btn btn-info mx-2 d-print-none"><fa-icon [icon]="faPrint"></fa-icon></button>
      <button [disabled]="generatingfile" (click)="downloadScreen(selectedPremise.unit_number)" class="btn btn-info mx-2 d-print-none">
        <fa-icon *ngIf="!generatingfile" [icon]="faDownload"></fa-icon>
        <span *ngIf="generatingfile" class="spinner-border spinner-border-sm mr-1"></span>
      </button>
      <!-- <button (click)="viewStatement()" class="btn btn-info mx-2 d-print-none"><fa-icon [icon]="faFloppyDisk"></fa-icon></button> -->


    </div>

  </div>


    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="propertyname">Property Name</label>
      <input class="form-control col-3"  type="text" [value]="property.name"  readonly/>
    </div>
  <div class="form-group my-4">
    <label class="form-label fs-5 fw-light" for="lotnumber">Lot/Apartment #</label>
    <input class="form-control col-auto" [value]="selectedPremise.unit_number"  type="text" readonly/>
  </div>
  <div class="row">

        <div class="col-md-5 my-2">
          <label class="form-label fs-5 fw-light" for="firstname">Name of Owner(s)</label>
          <span *ngFor="let owner of ownerData">
            <input class="form-control col-3 my-1"  type="text" [value]="owner?.firstname + ' ' + owner?.lastname"  readonly/>
          </span>
        </div>

        <div class="col-md-5 border-start my-2">
          <label class="form-label fs-5 fw-light" for="mortgages">Mortgage(s)</label>
        <table class="table  table-bordered">

            <thead>
              <tr>
                <td>Payee</td>
                <td>Institution</td>
                <td>Start Date</td>
                <td>End Date</td>
                <td class="d-print-none">Action(s)</td>
              </tr>

            </thead>
            <tbody>
                <tr *ngFor="let mortgage of mortgageData">
                  <td>{{mortgage.payee}}</td>
                  <td>{{mortgage.lender}}</td>
                  <td>{{mortgage.startDate | date: 'MMM-dd-y':'UTC'}}</td>
                  <td>{{mortgage.endDate | date: 'MMM-dd-y':'UTC'}}</td>
                  <td class="d-print-none"><button (click)="viewMortgage(mortgage.id)" class="mt-4 btn btn-sm btn-primary d-print-none">View <fa-icon [icon]="faEye"></fa-icon></button></td>
                </tr>

            </tbody>





      </table>
        </div>


        <!-- <tr *ngFor="let obligation of obligations"></tr> -->
        <!-- <input class="form-control col-3"  type="text" [value]="ownerData[0]?.firstname + ' ' + ownerData[0]?.lastname"  readonly/> -->
    </div>
    <div class="row">
      <div class="form-group col-md-5 my-4">
        <label class="form-label fs-5 fw-light" for="entitlement">Number of Room(s)</label>
        <input class="form-control col-xs-3" [value]="selectedPremise.number_rooms"  type="text" readonly/>
      </div>
      <div class="form-group col-md-5 my-4">
        <label class="form-label fs-5 fw-light" for="entitlement">Unit of Entitlement</label>
        <input class="form-control col-xs-3" [value]="selectedPremise.unit_entitlement"  type="text" readonly/>
      </div>
    </div>

    <div class="form-group my-4">
        <div class="row">
          <label class="form-label fs-5 fw-light" for="firstname">Maintenance Outstanding</label>
        </div>

        <div class="d-inline-flex">
          <div class="col-auto input-group">
            <input class="form-control" type="text" [value]="maintenanceoutstanding | currency" readonly/>
            <button class="btn btn-outline-primary d-print-none" type="button" (click)="toggleIcon('maintenance')"
            data-bs-toggle="collapse" data-bs-target="#collapseMaintenances"
            aria-expanded="false" aria-controls="collapseMaintenances">
              <fa-icon *ngIf="maintenanceview" [icon]="faAngleUp"></fa-icon>
              <fa-icon *ngIf="!maintenanceview" [icon]="faAngleDown"></fa-icon>
            </button>
          </div>

          <div class="col-auto mx-2 d-print-none">
            <button (click)="addMaintenance()" class="btn btn-primary"><fa-icon [icon]="faCirclePlus"></fa-icon></button>
          </div>

        </div>
        <div id="collapseMaintenances" class="accordion-collapse collapse mt-4" aria-labelledby="headingOne" data-bs-parent="#collapseMaintenances">
          <div class="accordion-body">
              <table class="table table-striped table-bordered">
                  <thead class="py-1">
                    <th scope="col">Period <button class="btn btn-sm btn-link" (click)="toggleSort()"><fa-icon [icon]="faSort"></fa-icon></button></th>
                    <th scope="col">Description</th>
                    <th scope="col">Amount</th>
                    <th class="d-print-none" scope="col">Option(s)</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let obligation of maintenances | orderBy: 'period' : sortOrder ">
                      <td>{{obligation.period | date: 'MMM-y':'UTC'}}</td>
                      <td>{{obligation.description}}</td>
                      <td>{{obligation.amount | currency}}</td>
                      <td class="d-print-none"><button (click)="viewObligation(obligation.id)" class="btn btn-primary d-print-none">View <fa-icon [icon]="faEye"></fa-icon></button></td>
                    </tr>

                  </tbody>

                </table>
          </div>
        </div>
    </div>
    <div class="form-group my-4">
        <div class="row">
          <label class="form-label fs-5 fw-light" for="firstname">Miscellaneous Fees Outstanding</label>
        </div>

        <div class="d-inline-flex">
          <div class="col-auto input-group">
            <input class="form-control" type="text" [value]="feestotal | currency" readonly/>
            <button class="btn btn-outline-primary d-print-none" (click)="toggleIcon('fees')"
            data-bs-toggle="collapse" data-bs-target="#collapseFees"
            aria-expanded="false" aria-controls="collapseFees">
              <fa-icon *ngIf="feesview" [icon]="faAngleUp"></fa-icon>
              <fa-icon *ngIf="!feesview" [icon]="faAngleDown"></fa-icon>
            </button>
          </div>
          <div class="col-auto mx-2 d-print-none">
            <button (click)="addFee()" class="btn btn-primary d-print-none"><fa-icon [icon]="faCirclePlus"></fa-icon></button>
          </div>

        </div>
        <div id="collapseFees" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFees">
          <div class="accordion-body">
              <table class="table table-striped mt-4 table-bordered">
                  <thead class="py-1">
                    <th scope="col">Period</th>
                    <th scope="col">Description</th>
                    <th scope="col">Amount</th>
                    <th class="d-print-none" scope="col">Option(s)</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let fee of fees | orderBy: 'period'">
                      <td>{{fee.period | date: 'MMM-y':'UTC'}}</td>
                      <td>{{fee.description}}</td>
                      <td>{{fee.amount | currency}}</td>
                      <td class="d-print-none"><button (click)="viewFee(fee.id)" class="btn btn-primary d-print-none">View <fa-icon [icon]="faEye"></fa-icon></button></td>
                    </tr>

                  </tbody>

                </table>
          </div>
        </div>
    </div>
    <div class="form-group my-4">
      <div class="row">
        <label class="form-label fs-5 fw-light" for="firstname">Payments YTD</label>
      </div>

      <div class="d-inline-flex">
        <div class="col-auto input-group">
          <input class="form-control" type="text" [value]="paymentstotal | currency" readonly/>
          <button class="btn btn-outline-primary d-print-none" (click)="toggleIcon('payments')"
            data-bs-toggle="collapse" data-bs-target="#collapsePayments"
            aria-expanded="false" aria-controls="collapsePayments">
            <fa-icon *ngIf="paymentsview" [icon]="faAngleUp"></fa-icon>
            <fa-icon *ngIf="!paymentsview" [icon]="faAngleDown"></fa-icon>
          </button>
        </div>
        <div class="col-auto mx-2 d-print-none">
          <button (click)="addPayment()" class="btn btn-primary d-print-none"><fa-icon [icon]="faCirclePlus"></fa-icon></button>
        </div>

      </div>
      <div id="collapsePayments" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
            <table class="table table-striped mt-4 table-bordered">
                <thead>
                  <th scope="col">Period</th>
                  <th scope="col">Paid By</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Trans. Date</th>
                  <th class="d-print-none" scope="col">Option(s)</th>
                </thead>
                <tbody>
                  <tr *ngFor="let payment of payments | orderBy: 'period_date'">
                    <td>{{payment.period_date | date: 'MMM-y':'UTC'}}</td>
                    <td>{{payment.paid_by}}</td>
                    <td>{{payment.amount | currency}}</td>
                    <td>{{payment.period_date | date :'dd-MMM-y':'UTC'}}</td>
                    <td class="d-print-none">
                      <button (click)="viewPayment(payment.id)" class="btn btn-primary m-1 d-print-none">View <fa-icon [icon]="faEye"></fa-icon></button>
                      <button (click)="deletePayment(payment.id)" class="btn btn-danger m-1 d-print-none">Delete <fa-icon [icon]="faCircleXmark"></fa-icon></button>
                    </td>
                  </tr>

                </tbody>

              </table>
        </div>
      </div>
    </div>

    <div *ngIf="property.id == 1" class="form-group my-4">
      <div class="row">
        <label class="form-label" for="firstname">Unverified NHT Payments YTD</label>
      </div>

      <div class="d-inline-flex">
        <div class="col-auto input-group">
          <input class="form-control" type="text" [value]="unverifiedpaymentstotal | currency" readonly/>
          <button class="btn btn-outline-primary" (click)="toggleIcon('unverifiedpayments')"
            data-bs-toggle="collapse" data-bs-target="#collapseunverifiedPayments"
            aria-expanded="false" aria-controls="collapseunverifiedPayments">
            <fa-icon *ngIf="unverifiedpaymentsview" [icon]="faAngleUp"></fa-icon>
            <fa-icon *ngIf="!unverifiedpaymentsview" [icon]="faAngleDown"></fa-icon>
          </button>
        </div>
        <div class="col-auto mx-2">
          <button class="btn btn-primary icon-bhover" (click)="toggleInfo('unverifiedpaymentsinfo')"><fa-icon [icon]="faInfoCircle"></fa-icon></button>

        </div>

      </div>
      <div id="collapseunverifiedPayments" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
            <table class="table table-striped mt-4 table-bordered">
                <thead>
                  <th scope="col">Period</th>
                  <th scope="col">Paid By</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Trans. Date</th>
                  <th scope="col">Option(s)</th>
                </thead>
                <tbody>
                  <tr *ngFor="let payment of unverifiedPayments">
                    <td>{{payment.period_date | date: 'MMM-y':'UTC'}}</td>
                    <td>{{payment.paid_by}}</td>
                    <td>{{payment.amount | currency}}</td>
                    <td>{{payment.effective_date | date :'dd-MMM-y':'UTC'}}</td>
                    <td>
                      <button (click)="viewPayment(payment.id)" class="btn btn-primary">View <fa-icon [icon]="faEye"></fa-icon></button>
                      <button (click)="deletePayment(payment.id)" class="btn btn-danger m-1 d-print-none">Delete <fa-icon [icon]="faCircleXmark"></fa-icon></button>
                    </td>
                  </tr>

                </tbody>

              </table>
        </div>
      </div>
      <div id="unverifiedpaymentsinfo" class="alert alert-info mt-1 col-md-6"><b>Unverified Payments YTD</b> show payments that are made to maintenance fee because the property still has the original NHT mortage that was started in 2006. If you have completed paying your original NHT mortgage, please email <a href="mailto:caribbeanpalmsestate@gmail.com?subject='Mortgage completed?I have completed my original NHT mortgage">caribbeanpalmsestate&#64;gmail.com</a></div>
    </div>
    <div class="d-none d-print-block">
      Generated on {{generationDate}}
    </div>
    <div class="mt-4">
      <button (click)="goBack()" class="btn btn-primary d-print-none"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>
      <button (click)="goHome()" class="btn btn-info float-end d-print-none"><fa-icon [icon]="faHomeAlt"></fa-icon> Home</button>
    </div>

</div>

<div class="container" *ngIf="action == 'create'">
    <h1 class="display-6">Add Premises</h1>
    <form [formGroup]="addPremisesForm" (ngSubmit)="onSubmitPremises()">
      <div class="form-group my-4">
        <label class="form-label fs-5 fw-light" for="firstname">Lot/Apartment #</label>
        <input class="form-control" placeholder="Lot/Apartment number" formControlName="unit_number" [ngClass]="{ 'is-invalid': submitting && ap.unit_number.errors, 'is-valid': ap.unit_number.valid && ap.unit_number.touched }"/>
      </div>
      <div class="form-group my-4">
        <label class="form-label fs-5 fw-light" for="firstname">Number of rooms</label>
        <input class="form-control" placeholder="Number of rooms" formControlName="number_rooms" [ngClass]="{ 'is-invalid': submitting && ap.number_rooms.errors, 'is-valid': ap.number_rooms.valid && ap.number_rooms.touched }"/>
      </div>
      <div class="form-group my-4">
        <label class="form-label fs-5 fw-light" for="firstname">Unit of Entitlement</label>
        <input class="form-control" placeholder="Unit of Entitlement" formControlName="unit_entitlement" [ngClass]="{ 'is-invalid': submitting && ap.unit_entitlement.errors, 'is-valid': ap.unit_entitlement.valid && ap.unit_entitlement.touched }"/>
      </div>

      <div class="form-group my-4">
        <label class="form-label" for="firstname">Size (in sqft)</label>
        <input class="form-control" placeholder="Size (in sqft)" formControlName="size" [ngClass]="{ 'is-invalid': submitting && ap.size.errors, 'is-valid': ap.size.valid && ap.size.touched }"/>
      </div>

      <button [disabled]="loading" class="btn btn-primary mt-4" >
        Create <fa-icon *ngIf="!loading" [icon]="faPaperPlane"></fa-icon>
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>

      </button>
    </form>

</div>

<div class="container mt-4" *ngIf="action == 'addassociation'">
  <form [formGroup]="addAssociationForm" (ngSubmit)="onSubmitAssociation()">
    <h5 class="fs-2 fw-light mt-4">Please fill out form below to association user this property</h5>
    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="firstname">Email</label>
      <input class="form-control" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitting && ap.unit_number.errors, 'is-valid': ap.unit_number.valid && ap.unit_number.touched }"/>
    </div>
    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="firstname">Property Name</label>
      <input class="form-control" placeholder="Property Name" formControlName="property_name" [ngClass]="{ 'is-invalid': submitting && ap.unit_number.errors, 'is-valid': ap.unit_number.valid && ap.unit_number.touched }"/>
    </div>
    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="firstname">Lot/Apt #</label>
      <input class="form-control" placeholder="Lot/Apartment number" formControlName="unit_number" [ngClass]="{ 'is-invalid': submitting && ap.unit_number.errors, 'is-valid': ap.unit_number.valid && ap.unit_number.touched }"/>
    </div>
    <input class="form-control" type="hidden" formControlName="premiseId" />
    <input class="form-control" type="hidden" formControlName="propertyId" />


    <div class="mt-4">
      <button (click)="goBack()" class="btn btn-warning"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>
      <button [disabled]="submittingassociation" class="btn btn-primary float-end" >
        Save <fa-icon *ngIf="!submittingassociation" [icon]="faPaperPlane"></fa-icon>
        <span *ngIf="submittingassociation" class="spinner-border spinner-border-sm mr-1"></span>

      </button>
    </div>
  </form>
</div>

<div class="container mt-4" *ngIf="action == 'editassociation'">
  <h1 class="display-6">Edit Association</h1>


  <form [formGroup]="editAssociationForm" >
    <input class="form-control" type="hidden" formControlName="id" readonly/>
    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="firstname">Email</label>
      <input class="form-control" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitting && ap.unit_number.errors, 'is-valid': ap.unit_number.valid && ap.unit_number.touched }"/>
    </div>
    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="firstname">Property Name</label>
      <input class="form-control" placeholder="Property Name" formControlName="property_name" [ngClass]="{ 'is-invalid': submitting && ap.unit_number.errors, 'is-valid': ap.unit_number.valid && ap.unit_number.touched }"/>
    </div>
    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="firstname">Lot/Apt #</label>
      <input class="form-control" placeholder="Lot/Apartment number" formControlName="unit_number" [ngClass]="{ 'is-invalid': submitting && ap.unit_number.errors, 'is-valid': ap.unit_number.valid && ap.unit_number.touched }"/>
    </div>
    <input class="form-control" type="hidden" formControlName="premiseId" readonly/>
    <input class="form-control" type="hidden" formControlName="propertyId" readonly/>
  </form>
  <div class="my-4">
    <button (click)="goBack()" class="btn btn-warning"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>
    <button [disabled]="editingassociation" (click)="onSaveEditAssociation()" class="btn btn-primary float-end" >
      Update <fa-icon *ngIf="!editingassociation" [icon]="faPaperPlane"></fa-icon>
      <span *ngIf="editingassociation" class="spinner-border spinner-border-sm mr-1"></span>

    </button>
  </div>

</div>

<div class="container mt-4" *ngIf="action == 'viewassociation'">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="fs-2 fw-light mt-4">View Association</h1>
    <button (click)="editAssociation()" class="mt-4 btn btn-primary">Edit <fa-icon [icon]="faPenToSquare"></fa-icon></button>
  </div>
  <form [formGroup]="viewAssociationForm" >
    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="firstname">Email</label>
      <input class="form-control" placeholder="Email" formControlName="email" />
    </div>
    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="firstname">Property Name</label>
      <input class="form-control" placeholder="Property Name" formControlName="property_name" />
    </div>
    <div class="form-group my-4">
      <label class="form-label fs-5 fw-light" for="firstname">Lot/Apt #</label>
      <input class="form-control" placeholder="Lot/Apartment number" formControlName="unit_number" />
    </div>
  </form>
</div>

<div class="container mt-4" *ngIf="action == 'listassociation'">

  <div class="d-flex justify-content-between align-items-center">
    <h1 class="fs-2 fw-light mt-4">View Association(s)</h1>
    <button (click)="onAddAssociation()" class="mt-4 btn btn-primary">Add <fa-icon [icon]="faUserPlus"></fa-icon></button>
  </div>
  <table class="table table-striped mt-4 table-bordered">
    <thead>
      <th scope="col">Email</th>
      <th>Lot/Apt #</th>
      <th scope="col">Option(s)</th>
    </thead>
    <tbody>
      <tr *ngFor="let association of associationData">
        <td>{{association.email}}</td>
        <td>{{association.unit_number}}</td>
        <td>
          <button (click)="viewAssociation(association.id)" class="btn btn-primary m-1">View <fa-icon [icon]="faEye"></fa-icon></button>
          <button (click)="deleteAssociation(association.id)" class="btn btn-danger m-1">Delete <fa-icon [icon]="faCircleXmark"></fa-icon></button>
        </td>
      </tr>

    </tbody>

  </table>

  <div class="mt-4">
    <button (click)="goBack()" class="btn btn-primary"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>
    <button (click)="goHome()" class="btn btn-info float-end"><fa-icon [icon]="faHomeAlt"></fa-icon> Home</button>
  </div>
</div>

<div class="container mt-4" *ngIf="action == 'removeassociation'">

</div>

