import { Component } from '@angular/core';

@Component({
  selector: 'app-obligation',
  standalone: false,
  
  templateUrl: './obligation.component.html',
  styleUrl: './obligation.component.css'
})
export class ObligationComponent {

}
