import { Component } from '@angular/core';
import { version } from '../../package.json'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false,
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'billdeo';

  currentyear: number = new Date().getFullYear();

  VNUM: string | undefined;

  ngOnInit() {
    // this.afMessaging.messages
    // .subscribe((message) => {
    //     this.messages = message;
    // });
    this.VNUM = version;
  }

}
