import { Component, ElementRef, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { PremiseService } from '../_services/premise.service';
import { CurrencyPipe, Location } from '@angular/common';
import { PropertyService } from '../_services/property.service';
import { environment } from '../../environments/environment';
import { faBed, faEye, faHashtag, faSearch, faCircleArrowLeft, faCirclePlus,
        faPaperPlane, faAngleDown, faAngleUp, faHomeAlt, faSms, faCircleXmark,
        faPenToSquare, faUsersViewfinder, faUserPlus, faPrint, faDownload,
        faSort, faFloppyDisk, faInfoCircle }
        from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
//import * as moment from 'moment';
import moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../_services';
import { faLess, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Obligation } from '../_models/obligation';
import { FinancialService } from '../_services/financial.service';
import { Payment } from '../_models/payment';
import { Owner } from '../_models/owner';
import { Mortgage } from '../_models/mortgage';
import { PremiseAssociation } from '../_models/premise_association';
import { NotificationService } from '../_services/notification.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '../_services/app.service';
//import * as jsPDF from 'jspdf';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


@Component({
    selector: 'app-manage-premises',
    templateUrl: './manage-premises.component.html',
    styleUrls: ['./manage-premises.component.css'],
    standalone: false
})

export class ManagePremisesComponent {
  @ViewChild('content', { 'static': true }) content:ElementRef | undefined;
  loading = false;
  action: any;

  generatingfile = false;

  submitting = false;
  submittingassociation = false;
  editingassociation = false;

  searchPremise!: string;

  faEye = faEye;
  faHashTag = faHashtag;
  faInfoCircle = faInfoCircle;
  faFloppyDisk = faFloppyDisk;
  faDownload = faDownload;
  faPrint = faPrint;
  faBed = faBed;
  faHomeAlt =  faHomeAlt;
  faSearch = faSearch;
  faCircleArrowLeft = faCircleArrowLeft;
  faCirclePlus = faCirclePlus;
  faCircleXmark = faCircleXmark;
  faPaperPlane = faPaperPlane;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faWhatsapp = faWhatsapp;
  faSms = faSms;
  faPenToSquare = faPenToSquare;
  faUsersViewfinder = faUsersViewfinder;
  faUserPlus = faUserPlus;
  faSort = faSort;

  addPremisesForm!: FormGroup;
  editPremisesForm!: FormGroup;

  addAssociationForm!: FormGroup;
  editAssociationForm!: FormGroup;
  viewAssociationForm!: FormGroup;

  ownerData!: Owner[];
  mortgageData!: Mortgage[];
  associationData!: PremiseAssociation[];

  generationDate = moment().format('MMMM D YYYY hh:mm:ss');


  obligations!: Obligation[];
  payments!: Payment[];
  unverifiedPayments!: Payment[];
  fees!: Obligation[];
  maintenances!: Obligation[];

  maintenanceoutstanding = 0;
  paymentstotal = 0;
  unverifiedpaymentstotal = 0;
  feestotal = 0;

  paymentsview = false;
  unverifiedpaymentsview  = false;
  feesview = false;
  maintenanceview = false;

  selectedPremise: any;
  selectedAssociation: any;
  property: any;

  sortOrder: "desc" | "asc" = "desc";

  p: number = 1;

  constructor(private toastr: ToastrService,
    private route: ActivatedRoute,
    private premiseService: PremiseService,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private propertyService: PropertyService,
    private financialService: FinancialService,
    private userService: UserService,
    private sendNotificationService: NotificationService,
    private _location: Location,
    private dialog: MatDialog,
    private appService: AppService,
    private router: Router) {

    }

  ngOnInit(): void {
    if (!environment.production) {
      console.log(':::NG Onit Called:::');
    }
    this.addPremisesForm = this.formBuilder.group({
      unit_number: ['', Validators.required],
      unit_entitlement: ['', Validators.required],
      propertyId: [''],
      number_rooms: ['', Validators.required],
      size: ['', Validators.required]
    });

    this.addAssociationForm = this.formBuilder.group({
      email:  ['', Validators.required],
      unit_number: ['', Validators.required],
      property_name: ['', Validators.required],
      premiseId: ['', Validators.required],
      propertyId: ['', Validators.required],
      enabled: ['', Validators.required]
    });

    this.editAssociationForm = this.formBuilder.group({
      id: ['', Validators.required],
      email:  ['', Validators.required],
      unit_number: [{value: '', disabled: true}, Validators.required],
      property_name: [{value: '', disabled: true}, Validators.required],
      premiseId: [{value: '', disabled: true}, Validators.required],
      propertyId: [{value: '', disabled: true}, Validators.required],
      enabled: [{value: '', disabled: true}, Validators.required]
    });

    this.viewAssociationForm = this.formBuilder.group({
      email: [{value: '', disabled: true}, Validators.required],
      unit_number: [{value: '', disabled: true}, Validators.required],
      property_name: [{value: '', disabled: true}, Validators.required],
      premiseId: [{value: '', disabled: true}, Validators.required],
      propertyId: [{value: '', disabled: true}, Validators.required],
      enabled: [{value: '', disabled: true}, Validators.required]
    });

    this.route.params.subscribe((params: Params) => this.action = params['action']);
    //this.route.params.subscribe((params: Params) => this.action = params['id']);

    if (!environment.production) {
      console.log('Action selected: ', this.action);
    }

    if (this.action == 'view') {
      let premstr = localStorage.getItem('premise');

      this.selectedPremise = JSON.parse(premstr ? premstr : '');

      if (this.selectedPremise !== undefined) {
           this.loadPremiseData();
      }
    }


    this.propertyService.selectedProperty$.subscribe((value) => {
      if (value == undefined || value.length == undefined) {
        let propstr = localStorage.getItem('property');

        this.property = JSON.parse(propstr ? propstr : '');
      } else {
        this.property = value;
      }

      if (!environment.production) {
        console.log('Manage Premises: Property data: ',this.property);
      }

    });

    if (this.action == 'addassociation' || this.action == 'editassociation' || this.action == 'viewassociation'
        || this.action == 'listassociation') {
      let premstr = localStorage.getItem('premise');

      this.selectedPremise = JSON.parse(premstr ? premstr : '');

      if (this.selectedPremise !== undefined || this.selectedPremise == '') {
           this.loadPremiseData();
      }



      if (!environment.production) {
        //console.log('Selected Premise: Association:: ', this.selectedPremise);
        console.log('Property data: Association:: ', this.property)
        console.log('Selected Association: ', this.selectedAssociation);
      }

    }

    if (this.action == 'addassociation' || this.action == 'listassociation') {

      if (!environment.production) {
        console.log('Add/List: Premise Association: ', this.selectedPremise);
      }
      this.addAssociationForm.setValue({
        email: '', unit_number: this.selectedPremise.unit_number, property_name: this.property.name,
        propertyId: this.selectedPremise.propertyId, premiseId: this.selectedPremise.id, enabled: true
      });
    }

    if (this.action == 'editassociation' || this.action == 'viewassociation')
    {


      if (!environment.production) {
        console.log('Edit/View: Premise Association: ', this.selectedPremise);
      }

      let assocstr = localStorage.getItem('association');



      if (assocstr) {
        if (!environment.production) {
          console.log('True::: What is the value: ', assocstr)
        }
        this.selectedAssociation = JSON.parse(assocstr ? assocstr : '');
      } else {
        if (!environment.production) {
          console.log('False::: What is the value: ', assocstr)
        }
      }


      // if (this.selectedAssociation !== undefined || this.selectedAssociation == '') {

      // }
      this.addAssociationForm.setValue({
        email: '', unit_number: this.selectedPremise.unit_number, property_name: this.property.name,
        propertyId: this.selectedPremise.propertyId, premiseId: this.selectedPremise.id, enabled: true
      });

      this.editAssociationForm.setValue({
        id: this.selectedAssociation.id,
        email: this.selectedAssociation.email,
        unit_number: this.selectedAssociation.unit_number,
        property_name: this.property.name,
        premiseId: this.selectedAssociation.premiseId,
        propertyId: this.selectedAssociation.propertyId,
        enabled: this.selectedAssociation.enabled
      });
      if (!environment.production) {
        console.log('Edit/View: Premise Association: ', this.selectedAssociation);
      }
      this.viewAssociationForm.setValue({
        email: this.selectedAssociation.email,
        unit_number: this.selectedAssociation.unit_number,
        property_name: this.property.name,
        premiseId: this.selectedAssociation.premiseId,
        propertyId: this.selectedAssociation.propertyId,
        enabled: this.selectedAssociation.enabled
      });
    }


  }

  goBack() {
    this._location.back();
  }

  updateRouteInfo() {
    this.route.params.subscribe((params: Params) => this.action = params['action']);
  }

  goHome(): void {
    this.router.navigate(['/home'])
  }

  toggleSort(): void {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
  }

  toggleInfo(display: string) {
    if (!environment.production) {
      console.log('Info selected:::', display);
    }
    if (display == 'yourpaymentsinfo') {
     $('#yourpaymentsinfo').toggle();
    }

    if (display == 'maintenanceinfo') {
      $('#maintenanceinfo').toggle();
    }

    if (display == 'feesinfo') {
      $('#feesinfo').toggle();
    }

    if (display == 'unverifiedpaymentsinfo') {
      $('#unverifiedpaymentsinfo').toggle();
    }
  }

  get ap() { return this.addPremisesForm.controls; }

  get ep() { return this.editPremisesForm.controls; }

  viewPayment(id: number) {
    if (!environment.production) {
      console.log('Payment id:::::',id);
    };

    this.financialService.getPayment(id).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Payment data fetched: ', data);
        }
        this.financialService.setPayment(data);

        this.router.navigate(['/manage-payments/view']);
      },
      error: (error) => {
        if (!environment.production) {

          if (!environment.production) {
            console.log('Failed to fetch payment data: ', error);
          };
        }
      }

    });



  }

  triggerDeletePayment(id: number) {
    if (!environment.production) {
      console.log('Payment id:::::',id);
    };

    this.financialService.deletePayment(id).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Payment data deleted: ID: ', id);
        }

        this.toastr.success('Payment deleted successfully.');
        this.ngOnInit();
        this.router.navigate(['/manage-premises/view']);
      },
      error: (error) => {
        if (!environment.production) {

          console.log(error);
          this.toastr.error('Something went wrong. Fail to delete payment');
        }
      }

    });
  }

  deletePayment(id: number) {

    this.appService.setRecordId(id);

    this.openDialog('0ms', '0ms', 'payment');

  }

  viewFee(id: number) {

    this.financialService.getObligation(id).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Obligation fee data fetched: ', data);
        }
        this.financialService.setFee(data);

        this.router.navigate(['/manage-fees/view']);

      },
      error: (error) => {
        if (!environment.production) {
          console.log('Failed to fetch obligation fee data: ', error);
        }
      }

    });
  }

  viewMortgage(id: number) {
    this.financialService.getMortgage(id).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Mortgage data fetched: ', data);
        }

        this.financialService.setMortgage(data);
        this.router.navigate(['/manage-mortgage/view']);
      },
      error: (error) => {
        if (!environment.production) {
          console.log('Failed to fetch mortgage data: ', error);
        }
      }
    })
  }

  viewObligation(id: number) {
    this.financialService.getObligation(id).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Obligation data fetched: ', data);
        }
        this.financialService.setObligation(data);

        this.router.navigate(['/manage-maintenance/view']);
      },
      error: (error) => {
        if (!environment.production) {
          console.log('Failed to fetch obligation data: ', error);
        }
      }

    });

  }

  isEmpty(obj: any) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return true
  }

  loadAssociationData() {
    if (!environment.production) {
      console.log(':::Load Associaition Data Called::: ');



    }

    //this.premiseService.fetchPremiseAssociations(this.selectedPremise    propertyid: number, premiseid: number)
  }

  loadPremiseData() {
    if (!environment.production) {
      console.log(':::Load Premise Data Called::: ');

    }

    this.updateRouteInfo();
    this.premiseService.fetchPremiseData(this.selectedPremise.unit_number, this.selectedPremise.propertyId).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Result::: Fetch: Premise data: ', data);
          console.log('Property ID: ', data.propertyId);
          console.log('Premise ID: ', data.id);
          console.log('Selected Premise: ', this.selectedPremise)
        }
        this.ownerData = data.owners || [];
        this.obligations = data.obligations || [];
        this.mortgageData = data.mortgages || [];

        this.premiseService.fetchPremiseAssociations(this.selectedPremise.propertyId, this.selectedPremise.id).subscribe({
          next: (associationdata) => {
            if (!environment.production) {

              console.log('Data Fetch: Premises Association: ', associationdata);
              console.log('Is associations found::::',this.isEmpty(associationdata));
            }

            if (!this.isEmpty(associationdata)) {
               this.associationData = associationdata;
            }

          },
          error: (error) => {
            if (!environment.production) {
              console.log('Error: Fetch Premises Association: ', error);
            }
          }
        })



        this.feestotal = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 2).reduce(function(curr, prev) {
          return curr + parseFloat(prev.amount.toString());

        }, 0);

        this.fees = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 2);

        this.maintenances = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 1).filter(({settled}) => settled == false);


        this.maintenances.map(m => m.amount = m.amount - m.payment_obligations.reduce(function(curr, prev) {
          return curr + parseFloat(prev.amount.toString());

        }, 0));

        this.maintenanceoutstanding = this.maintenances.reduce(function(curr, prev) {
          return curr + parseFloat(prev.amount.toString());

        }, 0);

        this.payments = data.payments?.filter(({paid_by}) => paid_by != 'System-Adjustment') || [];

        this.unverifiedPayments = data.payments?.filter(({paid_by}) => paid_by == 'System-Adjustment') || [];

        this.paymentstotal = this.payments.reduce(function(curr, prev) {
          return curr + parseFloat(prev.amount.toString());
        }, 0);

        this.unverifiedpaymentstotal = this.unverifiedPayments.reduce(function(curr, prev) {
          return curr + parseFloat(prev.amount.toString());
        }, 0);

        //further reduce maintenance outstanding
        //this.maintenanceoutstanding = this.maintenanceoutstanding - this.paymentstotal;
      },
      error: (error) => {
        if (!environment.production) {
          console.log('Error: Fetch Premise Data::: ', error);
        }
      }
    })
  }

  viewAssociations() {
    this.action = 'listassociation';
    this.loadPremiseData();
    this.router.navigate(['/manage-premises/listassociation']);
  }

  onSelectedPremises(premise: any) {
    this.selectedPremise = premise;
    this.premiseService.setPremise(premise);
    this.loadPremiseData();


    // this.userService.getPerson(this.selectedPremise.id, this.selectedPremise.propertyId).subscribe({
    //   next: (data) => {
    //     if (!environment.production) {
    //       console.log('Owner data: ', data);
    //     }
    //     this.ownerData = data;
    //     localStorage.setItem('selectedOwner', JSON.stringify(this.ownerData));
    //   },
    //   error: (error) => {
    //     console.log('Error: Manage Premises: ', error)
    //   }
    // });

    // let request = { propertyId: this.selectedPremise.propertyId, unit_number:  this.selectedPremise.unit_number}
    // this.financialService.getObligations(request).subscribe({
    //   next: (data) => {
    //     this.obligations = data;
    //     this.maintenanceoutstanding = this.obligations.reduce(function(curr, prev) {
    //       return curr + parseFloat(prev.amount.toString());
    //     }, 0);

    //     if (!environment.production) {
    //       console.log('Maintenance obligations: ', data);
    //       console.log('Total amount: ', this.maintenanceoutstanding);
    //     }
    //   },
    //   error: (error) => {

    //   }
    // });

    // this.financialService.getFees(request).subscribe({
    //   next: (data) => {
    //     this.fees = data;
    //     if (!environment.production) {
    //       console.log('Fees: ', data);
    //       this.feestotal = this.fees.reduce(function(curr, prev) {
    //         return curr + parseFloat(prev.amount.toString());
    //       }, 0);
    //     }
    //   },
    //   error: (error) => {

    //   }
    // });




    if (!environment.production) {
      console.log('Premises selected: ', premise);
    }
    this.router.navigate(['/manage-premises/view']);
  }

  onAddPremises() {
    this.router.navigate(['/manage-premises/create']);
  }

  onAddAssociation() {
    this.action == 'addassociation';
    this.ngOnInit();
    this.loadPremiseData();
    this.router.navigate(['/manage-premises/addassociation']);
  }

  toggleIcon(display: string) {
    if (display == 'payments') {
      this.paymentsview = !this.paymentsview;
    }

    if (display == 'unverifiedpayments') {
      this.unverifiedpaymentsview = !this.unverifiedpaymentsview;
    }

    if (display == 'maintenance') {
      this.maintenanceview = !this.maintenanceview;
    }

    if (display == 'fees') {
      this.feesview = !this.feesview;
    }
  }

  sendSmsUser() {
    let fullname = this.ownerData[0].firstname + ' ' + this.ownerData[0].lastname;
    let phonenumber = '18762880596';
    console.log('Selected premises: ', this.selectedPremise);

    console.log('Owners: ', this.ownerData);
    let amount = this.maintenanceoutstanding;
    let message = `Good day ${fullname},\n\nYou have an outstanding maintenance of ${this.currencyPipe.transform(amount)}. ` +
                  `Please pay this amount by the 30th of this month.` +
                  //`\n\nRegards,\n\n${this.property.name} Management`;
                  `\n\nRegards,\n\nCPE Management`;

    let request = { 'receiver': phonenumber, 'message': message };
    console.log('Request: ', request);

    this.sendNotificationService.sendNotification(request).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Notification sent: ', data);
          alert('SMS sent successfully');
        }
      },
      error: (error) => {
        if (!environment.production) {
          console.log('Failed to send notification: ', error);
          alert('Failed to send SMS');
        }
      }
    });
  }

  sendWhatsappUser(username: string) {
    let fullname = this.ownerData[0].firstname + ' ' + this.ownerData[0].lastname;
    let phonenumber = '18762880596';
    console.log('Selected premises: ', this.selectedPremise);

    console.log('Owners: ', this.ownerData);
    let amount = this.maintenanceoutstanding;
    let message = `Good day ${fullname},\n\nYou have an outstanding maintenance of ${this.currencyPipe.transform(amount)}. ` +
                  `Please pay this amount by the 30th of this month.` +
                  `\n\nRegards,\n\n${this.property.name} Management`;
    location.href = "https://wa.me/+1" + phonenumber + "/?text=" + message;
    // this.userService.getUserProfile(username).subscribe(
    //   data => {
    //     this.userprofile = data;
    //     if (!environment.production) {
    //       console.log('Profile: ', this.userprofile)
    //     }
    //     location.href = "https://wa.me/+1" + this.userprofile.phone + "/?text=";

    //   },
    //   error => {
    //     this.toastr.error('Failed to retrieve user profile.', 'Error');
    //   }

    // );

  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, optionDelete: string): void {
    const dialogRef = this.dialog.open(DialogAnimationsExampleDialog, {
      width: '300px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.appService.selectedButton$.subscribe((value) => {
        if (value == "yes") {
          if (optionDelete == "payment") {
            //trigger payment deletion logic
            this.appService.selectedRecordId$.subscribe((value) => {
              this.triggerDeletePayment(value)
            });
          }

          if (optionDelete == "association") {
            //trigger premise assocation deletion logic
            this.appService.selectedRecordId$.subscribe((value) => {
              this.triggerDeleteAssociation(value)
            });

          }
          //user agrees to delete item

        } else  {
          //user selects no to delete, do nothing

        }
      });
    });

    dialogRef.backdropClick().subscribe(result => {
      //user does not agree to delete
      if (!environment.production) {
        console.log('Dialog closed without selecting option');
      }

    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  triggerDeleteAssociation(id: number) {
      //get record id from service
      if (!environment.production) {
        console.log('Assocation ID:::::',id);
      };

      this.premiseService.deleteAssociation(id).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log('Association data deleted: ID: ', id);
          }

          this.toastr.success('Association deleted successfully.');
          this.loadPremiseData()
          this.router.navigate(['/manage-premises/listassociation']);
        },
        error: (error) => {
          if (!environment.production) {

            console.log(error);
            this.toastr.error('Something went wrong. Fail to delete association');
          }
        }

      });
  }

  deleteAssociation(id: number) {

    this.appService.setRecordId(id);

    this.openDialog('0ms', '0ms', 'association');

  }

  viewAssociation(id: number) {

    this.premiseService.fetchPremiseAssociation(id).subscribe({
      next: (data) => {
        if (!environment.production) {
          console.log('Association data fetched: ', data);
        }

        this.premiseService.setAssociation(data);

        this.router.navigate(['/manage-premises/viewassociation']);
      },
      error: (error) => {
        if (!environment.production) {
          console.log('Failed to fetch association data: ', error);
        }
      }

    });
  }

  editAssociation() {
    this.router.navigate(['/manage-premises/editassociation']);
  }

  onSaveEditAssociation() {
    this.editingassociation = true;

    if (!this.editAssociationForm.valid) {
      this.editingassociation = false;
      return;
    }

    let associationupdatedata = this.editAssociationForm.value;

    if (!environment.production) {
      console.log('Update Property Association: Association data: ', associationupdatedata);
    }

    this.premiseService.updatePremiseAssociation( associationupdatedata.id, associationupdatedata).subscribe({
      next: (data) => {
        this.toastr.success(data.message, 'Success');
        this.submittingassociation = false;
        this.ngOnInit();
        this.router.navigate(['/manage-premises/listassociation']);
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
        this.submittingassociation = false;
      }
    })
  }

  printScreen() {
    window.print();
  }


downloadScreen(premise: number) {
    this.generatingfile = true;
    const div = document.getElementById('content')!;
    const options = {
      background: 'white',
      scale: 3,
      ignoreElements: (element: Element) => {
        return element.classList.contains('d-print-none') ? true : false;
      }

    };
    html2canvas(div, options).then((canvas) => {

      var img = canvas.toDataURL("image/PNG");
      var doc = new jsPDF('p', 'mm', 'a4');

      // Add image Canvas to PDF
      const bufferX = 5;
      const bufferY = 5;
      const imgProps = (<any>doc).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      return doc;
    }).then((doc) => {
      let filename = 'statement' + '_lot_' + premise + '_' + moment().format('MMMDDYYYY') + '.pdf';
      doc.save(filename);
      this.generatingfile = false;
    });

  }
  //downloadScreen(): void {
    //const data = document.getElementById('content');
    //const data = document.querySelector('#content') ?? document.createElement("div");

    // html2canvas(data).then(canvas => {
    //   var imgWidth = 208;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   const contentDataURL = canvas.toDataURL('image/png')
    //   let pdf = new jspdf('p', 'mm', 'a4');
    //   var position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //   pdf.save('statement.pdf');
    // });
  //}
  // @ViewChild('content') content: ElementRef;
  //downloadScreen(): void {
    //let content=this.content.nativeElement;
 //   let content = document.querySelector('#content');
    //let doc = new jspdf('p', 'mm', 'a4');
    // let _elementHandlers =
    // {
    //   '#editor':function(element,renderer){
    //     return true;
    //   }
    // };

    // doc.fromHTML(content.innerHTML,15,15,{

    //   'width':190,
    //   'elementHandlers':_elementHandlers
    // });

    //doc.save('test.pdf');

  //}

  viewStatement() {

  }

  onSubmitAssociation() {
    this.submittingassociation = true;

    if (!this.addAssociationForm.valid) {
      this.submittingassociation = false;
      return;
    }

    let associationdata = this.addAssociationForm.value;

    if (!environment.production) {
      console.log('Create Property Association: Association data: ', associationdata);
    }

    this.premiseService.createPremiseAssociation(associationdata).subscribe({
      next: (data) => {
        this.toastr.success(data.message, 'Success');
        this.submittingassociation = false;
        this.ngOnInit();
        this.router.navigate(['/manage-premises/listassociation']);
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      }
    })
  }


  onSubmitPremises() {
    this.submitting = true;
    this.loading = true;

    if (!this.addPremisesForm.valid) {
      this.loading = false
      return;
    }

    let premisedata = this.addPremisesForm.value;

    premisedata.propertyId = this.property.id;

    if (!environment.production) {
      console.log('Create Premises: Premises data: ', premisedata);


    }

    this.premiseService.createPremises(premisedata).subscribe({
      next: (data) => {
        this.toastr.success(data.message, 'Success');
        this.loading = false;
        //this.ngOnInit();
        //this.loadPremiseData()
        this.router.navigate(['/home']);
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
        this.loading = false;
      }
    });

    this.submitting = false;
  }

  addPayment() {
    this.router.navigate(['/manage-payments/create']);
  }

  addFee() {
    this.router.navigate(['/manage-fees/create']);
  }

  addMaintenance() {
    this.router.navigate(['/manage-maintenance/create']);
  }
}

@Component({
    selector: 'dialog-animations-message-dialog',
    //templateUrl: 'dialog-animations-message-dialog.html',
    template: `<div class="mat-dialog-container">
  <h1 mat-dialog-title>Confirm Delete</h1>
  <div mat-dialog-content>
    Are you sure you want to delete this item?<br>
  </div>
  <div class="my-2" mat-dialog-actions>
    <button class="btn btn-info dialog-btn" (click)="SelectedNo()" >No</button>
    <button id="test" class="dialog-btn btn btn-primary mx-4" (click)="SelectedYes()"   cdkFocusInitial>Yes</button>
  </div>

  </div>`,
    styleUrls: ['./manage-premises.component.css'],
    standalone: false
})
export class DialogAnimationsExampleDialog {
  constructor(public dialogRef: MatDialogRef<DialogAnimationsExampleDialog>, private appService: AppService) {}

 SelectedNo() {
    //open information dialog
    this.appService.setButton("no");
    this.dialogRef.close();
  }

  SelectedYes() {
    this.appService.setButton("yes");
    this.dialogRef.close();

  }

}
