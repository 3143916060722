import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
//import * as moment from 'moment';
import moment from 'moment';
import { faCircleArrowLeft, faPaperPlane, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FinancialService } from '../_services/financial.service';
import { PremiseService } from '../_services/premise.service';
import { Maintenance } from '../_models/maintenance';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Mortgage } from '../_models/mortgage';
import { Payment } from '../_models/payment';
import { AppService } from '../_services/app.service';


@Component({
    selector: 'app-manage-payments',
    templateUrl: './manage-payments.component.html',
    styleUrls: ['./manage-payments.component.css'],
    standalone: false
})

export class ManagePaymentsComponent {

  action: any;

  selectedPremise: any;
  property: any;

  payment!: Payment;

  fetching = false;

  submitPaymentData = false;
  totalselected = 0;
  partpayment: any;
  partpaymentamount = 0;
  partpaymentamountotal = 0;
  partpaymentid = 0;

  outstandingMaintenance: Maintenance[] = [];
  obligationSelected: any[] = [];
  mortgageData!: Mortgage[];

  addPaymentForm!: FormGroup;
  editPaymentForm!: FormGroup;
  viewPaymentForm!: FormGroup;

  faCircleArrowLeft = faCircleArrowLeft;
  faPaperPlane = faPaperPlane;
  faPenToSquare = faPenToSquare;

  sortOrder: "desc" | "asc" = "desc";

  constructor(private route: ActivatedRoute,
     private _location: Location, private formBuilder: FormBuilder,
     private toastr: ToastrService, private financialService: FinancialService,
     private router: Router, private dialog: MatDialog, private appService: AppService,
     private premiseService: PremiseService,
  ) {

  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => this.action = params['action']);

    this.addPaymentForm = this.formBuilder.group({
      amount: ['',[ Validators.required, Validators.min(1)]],
      effective_date: ['', Validators.required],
      period_date: ['', Validators.required],
      description: [{ value: '', disabled: false }, Validators.required],
      paid_by: ['', Validators.required],
      premiseId: ['', Validators.required],
      payment_method: ['', Validators.required],
      obligationsPaid: [''],
      unitNumber: [''],
      propertyName: [''],
    });

    this.editPaymentForm = this.formBuilder.group({
      amount: ['', [Validators.required, Validators.min]],
      effective_date: ['', Validators.required],
      period_date: ['', Validators.required],
      description: [{ value: '', disabled: true }, Validators.required],
      paid_by: ['', Validators.required],
      premiseId: ['', Validators.required],
      payment_method: ['', Validators.required],
      unitNumber: [''],
      propertyName: [''],
    });

    this.viewPaymentForm = this.formBuilder.group({
      amount: [{ value: '', disabled: true }, Validators.required, Validators.min],
      effective_date: [{ value: '', disabled: true }, Validators.required],
      description: [{ value: '', disabled: true }, Validators.required],
      period_date: [{ value: '', disabled: true }, Validators.required],
      paid_by: [{ value: '', disabled: true }, Validators.required],
      premiseId: [{ value: '', disabled: true }, Validators.required],
      payment_method: [{ value: '', disabled: true }, Validators.required],
      unitNumber: [{ value: '', disabled: true }],
      propertyName: [{ value: '', disabled: true }],
    });

    let premstr = localStorage.getItem('premise');

    this.selectedPremise = JSON.parse(premstr ? premstr : '');

    let propertystr = localStorage.getItem('property');

    this.property = JSON.parse(propertystr ? propertystr : '');

    if (this.action == 'create') {


      this.addPaymentForm.setValue({
        amount: '', period_date: '', effective_date: '', obligationsPaid: '',
        propertyName: this.property.name, paid_by: '', payment_method: '', description: 'Payment for ',
        premiseId: this.selectedPremise.id, unitNumber: this.selectedPremise.unit_number
      });

      this.financialService.fetchUnpaidMaintenance(this.property.id, this.selectedPremise.id).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log("Unpaid Maintenance: ", data);
          }
          this.outstandingMaintenance = data;



        },
        error: (err) => {
          if (!environment.production) {
            console.log("Error: Unpaid maintenance:: ", err);
          }
        }

      });

      this.premiseService.fetchPremiseData(this.selectedPremise.unit_number, this.selectedPremise.propertyId).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log('Result::: Create Payment: Fetch Premise data: ', data);
            console.log('Selected Premise: ', this.selectedPremise)
          }
          this.mortgageData = data.mortgages || [];

          this.outstandingMaintenance.map(m => m.amount = m.amount - m.payment_obligations.reduce(function(curr, prev) {
            return curr + parseFloat(prev.amount.toString());

          }, 0));
          // if (this.property.name == "Caribbean Palms Estate") {
          //   if(!environment.production) {
          //     console.log(":::Business Rule::: Caribbean Palms Estate")
          //     console.log('Mortage data found:::', this.mortgageData);
          //   }
          //   let mortgageFound = this.mortgageData.find(p => p.lender == 'NHT' && moment(p.startDate) < moment("2007-01-01"));

          //   if (mortgageFound) {
          //     //let mortgageObject = mortgageFound[0];
          //     if (moment(mortgageFound.endDate) > moment("2900-12-31")) {
          //       this.outstandingMaintenance.map(m => m.amount = m.amount - 500);
          //       if(!environment.production) {
          //         console.log("::: Active mortgage found");
          //         console.log('Outstanding amounts updated: ', this.outstandingMaintenance);

          //       }



          //     }
          //   }

          // }
        },
        error: (error) => {
          if (!environment.production) {
            console.log('Error: Create Payment: Fetch Premise Data: ', error);
          }
        }
      });


    }

    if (this.action == 'view' || this.action == 'edit') {
      this.financialService.selectedPayment$.subscribe((value) => {


        if (value === undefined) {
          let paymentstr = localStorage.getItem('stpayment');
          this.payment = JSON.parse(paymentstr ? paymentstr : '');
        } else {
          this.payment = value;
        }
        if (!environment.production) {
          console.log('Payment selected: View/Edit ', this.payment);
        }
      });


      this.viewPaymentForm.setValue({
        amount: this.payment.amount, period_date: moment(this.payment.period_date).format('MMMM DD YYYY') , effective_date: moment(this.payment.effective_date).format('MMMM DD YYYY'),
        propertyName: this.property.name, paid_by: this.payment.paid_by, payment_method: this.payment.payment_method, description: this.payment.description,
        premiseId: this.payment.premise.id, unitNumber: this.payment.premise.unit_number
      });

      this.editPaymentForm.setValue({
        amount: this.payment.amount, period_date: this.payment.period_date, effective_date: this.payment.effective_date,
        propertyName: this.property.name, paid_by: this.payment.paid_by, payment_method: this.payment.payment_method,
        description: this.payment.description,
        premiseId: this.payment.premise.id, unitNumber: this.payment.premise.unit_number
      });


    }

  }

  get poa() { return this.addPaymentForm.controls; }
  get poe()  { return this.editPaymentForm.controls; }

  validatePayment() {
    let totalselected = 0;
    //Check if Maintenance periods selected for payment does not exceed payment
    totalselected += this.outstandingMaintenance.find(p => p.id == 1)!.amount;
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef = this.dialog.open(DialogAnimationsExampleDialog, {
      width: '300px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.appService.selectedButton$.subscribe((value) => {
        if (value == "createdepositbtn") {
          //pop last value, due to fact that it is part payment
          this.partpayment = this.obligationSelected.pop();
          if (!environment.production) {

            console.log('Part payment obligation id: ', this.partpayment.objectid);
          //  console.log('Part payment amount: ', this.partpaymentamount);

          }
          let obligationrefid = '#pcheck' + this.partpayment.objectid;
          $(obligationrefid).prop('checked', true);
          this.obligationSelected.push({ objectid: this.partpayment.objectid, periodamt: this.partpaymentamount});
          console.log(this.obligationSelected);

        } else  {
          //show information about not making deposit
          console.log('Showing information...');
          this.partpaymentamountotal = this.partpaymentamountotal - this.partpaymentamount;
        }
      });
    });

    dialogRef.backdropClick().subscribe(result => {
      console.log('closed without selecting option');
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onAmountChange(event: any) {
    let paymentamt = this.addPaymentForm.get('amount')!.value || 0;
    if (paymentamt < this.totalselected) {

    }
  }


  onCheckboxChange(event: any, objectid: number) {
    let paymentamt = this.addPaymentForm.get('amount')!.value || 0;
    let periodamt = parseFloat(this.outstandingMaintenance.find(p => p.id == event.target.value)!.amount.toString());

    let isselectionvalid = true;

    this.addPaymentForm.controls['obligationsPaid'].setValue(this.obligationSelected);

    //Update description
    let temp = this.addPaymentForm.value

    let payperiod = this.outstandingMaintenance.find(p => p.id == event.target.value)!.period

    let month =  moment(payperiod).format('MMMM')
    let year =  moment(payperiod).format('YYYY')

    this.addPaymentForm.controls['description'].setValue('Payment for ' + month + ' ' + year + ' ');
    //Update description

    if (event.target.checked) {

      this.obligationSelected.push({objectid, periodamt});

      this.totalselected = this.obligationSelected.reduce(function(curr, prev) {
        return curr + parseFloat(prev.periodamt.toString());
      }, 0);

      if (this.totalselected > paymentamt) {

        this.totalselected = this.obligationSelected.reduce(function(curr, prev) {
          return curr + parseFloat(prev.periodamt.toString());
        }, 0) - periodamt;
        if ((paymentamt - this.totalselected) > 0) {
          //prompt for part payment
          this.openDialog('0ms', '0ms');
          event.target.checked = false;
          this.partpaymentamount = paymentamt - this.totalselected;

        } else {
          this.toastr.error('The maintenance period selected for payment is greather than payment amount');
          this.obligationSelected.pop();
          event.target.checked = false;

        }

      } else {
        //this.obligationSelected.push({objectid, periodamt});
        if (!environment.production) {
          console.log('Payment amount greater that period total selected.....');
        }
      }

    } else {
      //this.totalselected -= periodamt;

      if (!environment.production) {
        console.log('item unchecked: ', objectid);
      }
      this.obligationSelected = this.obligationSelected.filter(obj => obj.objectid != objectid);
      //re-compute total
      this.totalselected = this.obligationSelected.reduce(function(curr, prev) {
        return curr + parseFloat(prev.periodamt.toString());
      }, 0)



    }

    if (isselectionvalid) {

      //this.maintenanceoutstanding = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 1).filter(({settled}) => settled == false).reduce(function(curr, prev) {
      //  return curr + parseFloat(prev.amount.toString());
      //
      //}, 0);




      //To-do: Remove object if already added
      //console.log('Lets observe: ', this.obligationSelected);
    }


    if (!environment.production) {
      console.log('Obligation selected and amount: ', this.obligationSelected);
      console.log('New Total: ', this.totalselected);
      console.log('Payment amount: ', paymentamt);
      console.log('Part payment amount: ', this.partpaymentamount);
      console.log('Part payment total: ', this.partpaymentamountotal);
    }

  }

  goBack() {
    this._location.back();
  }

  onEditPayment(): void {
    this.action = 'edit';
    this.router.navigate(['/manage-payments/edit']);
    //this.ngOnInit();
  }

  updatePayment(): void {
    if (!this.editPaymentForm.valid) {
      if (!environment.production) {
        console.log('Form value: ',this.editPaymentForm.value);
        console.log('Form not valid: ', this.editPaymentForm);
      }

      return;
    }

    let updateformdata = this.editPaymentForm.value;

    if (!environment.production) {
      console.log('Form data: Payment Update: ', updateformdata);
    }
  }


  createPayment(): void {
    this.action = 'create';

    this.submitPaymentData = true;

    if (!this.addPaymentForm.valid) {
      if (!environment.production) {
        console.log('Form value: ',this.addPaymentForm.value);
        console.log('Form not valid: ', this.addPaymentForm);
      }

      return;
    }
    let paymentamt = this.addPaymentForm.get('amount')!.value || 0;

    if (this.totalselected > paymentamt) {
      this.toastr.error('The maintenance selected for payment is greater than payment amount');
      return;
    }

    //To-do: Check if obligation selected is covered by payment amount

    let formdata = this.addPaymentForm.value;

    if (!environment.production) {
      console.log('Form data: Payment creation: ', formdata);
    }

    let request = {
      amount: formdata.amount,
      //period: formdata.period,
      period_date: moment(formdata.period_date).format('YYYY-MM-DD'),
      effective_date: moment(formdata.effective_date).format('YYYY-MM-DD'),
      description: formdata.description,
      premiseId: formdata.premiseId,
      obligationsPaid: formdata.obligationsPaid,
      payment_method: formdata.payment_method,
      paid_by: formdata.paid_by
    }

    if (this.partpaymentid != 0) {
      let partpaymentrequest = {
        amount: this.partpaymentamount,
        //period: formdata.period,
        period_date: moment(formdata.period_date).format('YYYY-MM-DD'),
        effective_date: moment(formdata.effective_date).format('YYYY-MM-DD'),
        premiseId: formdata.premiseId,
        description: formdata.description,
        obligationsPaid: [this.partpaymentid],
        payment_method: formdata.payment_method,
        paid_by: formdata.paid_by
      }
      if (!environment.production) {
        console.log('Part payment: ', partpaymentrequest)
      }
      // this.financialService.createPayment(partpaymentrequest).subscribe(
      //   {
      //     next: (data) => {
      //       if (!environment.production) {
      //         console.log('Part Payment creation response: ', data);
      //       }
      //     },
      //     error: (error) => {
      //       if (!environment.production) {
      //         console.log('Error: Part Payment creation: ', error);
      //       }

      //     }
      //   }
      // );
    }
    if (!environment.production) {
      console.log('Payment request: ', request);
    }

    this.financialService.createPayment(request).subscribe(
      {
        next: (data) => {
          if (!environment.production) {
            console.log('Payment creation response: ', data);
          }
          this.toastr.success(data.message, 'Success');

          this.router.navigate(['/manage-premises/view'])
        },
        error: (error) => {
          this.toastr.error(error, 'Error');
          if (!environment.production) {
            console.log('Error: Payment creation: ', error);
          }

        }
      }
    );

  }



}

@Component({
    selector: 'dialog-animations-message-dialog',
    //templateUrl: 'dialog-animations-message-dialog.html',
    template: `<div class="mat-dialog-container">
  <h1 mat-dialog-title>Information</h1>
  <div mat-dialog-content>
    This payment amount cannot cover outstanding maintenance selected.<br><br>
    Would you like to to make deposit?
  </div>
  <div class="my-2" mat-dialog-actions>
    <button class="btn btn-info dialog-btn" (click)="openInformationDialog()" >No</button>
    <button id="test" class="dialog-btn btn btn-primary" (click)="createDeposit()"   cdkFocusInitial>Yes</button>
  </div>

  </div>`,
    styleUrls: ['./manage-payments.component.css'],
    standalone: false
})
export class DialogAnimationsExampleDialog {
  constructor(public dialogRef: MatDialogRef<DialogAnimationsExampleDialog>, private appService: AppService) {}

  openInformationDialog() {
    //open information dialog
    this.appService.setButton("opendepositinfobtn");
    this.dialogRef.close();
  }

  createDeposit() {
    this.appService.setButton("createdepositbtn");
    this.dialogRef.close();

  }

}
