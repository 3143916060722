import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../_services';
import { faKey, faHome, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css'],
    standalone: false
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm! : FormGroup;
  loading = false;
  submitted = false;
  error = '';

  faKey = faKey;
  faHome = faHome;
  faPaperPlane = faPaperPlane;

  passwordPattern = "^[a-zA-Z0-9]{5,30}$";

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]]
    });
  }

  cancel() {
    this.router.navigate(['/'])
  }

  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {

        this.loading = false;
        return;
    }

    var data = this.changePasswordForm.value;

    var request = { password: data.password, token: this.route.snapshot.paramMap.get("token")};

    this.userService.changePassword(request).subscribe(
      {
        next: () => {
          this.toastr.info('Next block activated', 'Test');
        },
        error: (error) => {
          this.toastr.error(error, 'Error' );
        }
      }
      // data => {
      //   this.toasta.success({msg: data.message, title: 'Success'});
      //   this.loading = false;
      //   setTimeout(() => {
      //     this.router.navigate(['/'])
      //   }, 2000);
      // },
      // error => {
      //   this.toasta.error({msg: error, title: 'Error'});
      //   this.loading = false;
      // }
    );


  }


}
