<splash-screen [duration]="3" [animationDuration]="0.5"></splash-screen>
<div class="toolbar" role="banner">
    <img
      width="40"
      alt="Angular Logo"
      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg=="
    />
    <span>BillDeo</span>
      <div class="spacer"></div>
      <button class="btn btn-light mx-4">
        <fa-icon [icon]="faBell"></fa-icon>
      </button>
      <button class="btn btn-light  mx-4" (click)="logout()" >
        <fa-icon  [icon]="faSignOutAlt"></fa-icon>
      </button>
  </div>
  
  <div *ngIf="isAdmin()"  class="content" role="main">
  
    <!-- Highlight Card -->
    <div class="card highlight-card card-small">
  
      
     
      <span>Simplify your property management!</span>
  
  
  
    </div>
    

  </div>

  <div *ngIf="isUser() && !isAdmin()" class="content" role="main">

  </div>
  <div class="card highlight-card card-small">
    <span>Welcome to Premises by Billdeo</span>
  </div>

  <div *ngIf="isUser() && !isAdmin()" class="container">
    <div class="col-sm text-center my-4">
      <a routerLink="/notices"><fa-icon class="l-icon" [icon]="faBell"></fa-icon></a>
      <p routerLink="/notices">Notices</p>
    </div>
    <div class="col-sm text-center my-4">
      <a routerLink="/premises"><fa-icon class="l-icon" [icon]="faBuilding"></fa-icon></a>
      <p routerLink="/premises">Maintenance</p>
    </div>
    <div class="col-sm text-center my-4">
      <a routerLink="/breaches"><fa-icon class="l-icon" [icon]="faGavel"></fa-icon></a>
      <p routerLink="/breaches">Breaches</p>
    </div>
    <div class="col-sm text-center my-4">
      <a routerLink="/laws"><fa-icon class="l-icon" [icon]="faScaleBalanced"></fa-icon></a>
      <p routerLink="/laws">Laws</p>
    </div>
  </div>

  <div *ngIf="isAdmin()"  class="container">
    <!-- <div class="col-sm text-center my-4">
      <a routerLink="/query-maintenance"><fa-icon class="l-icon" [icon]="faSearch"></fa-icon></a>
      <p routerLink="/query-maintenance">Query Maintenance</p>
    </div> -->
    <div class="col-sm text-center my-4">
      <a routerLink="/notifications"><fa-icon class="l-icon" [icon]="faBell"></fa-icon></a>
      <p routerLink="/notifications">Notices</p>
    </div>
    <div class="col-sm text-center my-4">
      <a routerLink="/manage-property"><fa-icon class="l-icon" [icon]="faBuilding"></fa-icon></a>
      <p routerLink="/manage-property">Manage Property</p>
    </div>
    <div class="col-sm text-center my-4">
      <a routerLink="/compliance"><fa-icon class="l-icon" [icon]="faTachometerAlt"></fa-icon></a>
      <p routerLink="/compliance">Compliance</p>
    </div>
  </div>
    <!-- Resources -->
    <!-- <h2>Are you a Property owner?</h2>
    <p>Check your outstanding maintenance now</p>
  
    <div class="card-container">
  
      <a class="card" rel="noopener" href="/query-maintenance">
        
        <span><fa-icon class="l-icon fa-2x" [icon]="faSearch"></fa-icon> Query Maintenance</span>
        
      </a>
  
    </div> -->
  