import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { faCircleArrowLeft, faPaperPlane, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
//import * as moment from 'moment';
import moment from 'moment';
import { PropertyService } from '../_services/property.service';
import { PremiseService } from '../_services/premise.service';
import { ToastrService } from 'ngx-toastr';
import { FinancialService } from '../_services/financial.service';
import { Obligation } from '../_models/obligation';



@Component({
    selector: 'app-manage-maintenance',
    templateUrl: './manage-maintenance.component.html',
    styleUrls: ['./manage-maintenance.component.css'],
    standalone: false
})
export class ManageMaintenanceComponent {

  @ViewChild('picker') picker: any;

  action: any;

  faCircleArrowLeft = faCircleArrowLeft;
  faPaperPlane = faPaperPlane;
  faPenToSquare = faPenToSquare;

  fetching = false;

  submitObligationData = false;

  maintenance!: Obligation;

  addObligationForm!: FormGroup;
  editObligationForm!: FormGroup;
  viewObligationForm!: FormGroup;

  // period?: any;
  // public minDate?: any;
  // public maxDate?: any;
  // hideTime = true;
  // public disabled = false;
  // public showSpinners = true;
  // public showSeconds = false;
  // public disableMinute = true;
  // public enableMeridian = false;
  // public stepHour = 1;
  // public stepMinute = 1;
  // public stepSecond = 1;
  // public touchUi = false;
  // public color: ThemePalette = 'primary';

  selectedPremise: any;
  property: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
     private _location: Location, private formBuilder: FormBuilder,
     private propertyService: PropertyService,
    private premiseService: PremiseService,
    private financialService: FinancialService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => this.action = params['action']);

    // this.period = moment().endOf('month').toDate();
    // const now = new Date();
    // this.minDate = new Date();
    // this.minDate.setDate(now.getDate() - 1);
    // this.maxDate = new Date();
    // this.maxDate.setDate(now.getDate() + 1);

    this.addObligationForm = this.formBuilder.group({
      amount: ['', Validators.required],
      period: ['', Validators.required],
      premiseId: ['', Validators.required],
      description: ['', Validators.required],
      propertyId: ['', Validators.required],
      unitNumber: [''],
      propertyName: [''],
      obligationTypeId: ['']
    });

    this.viewObligationForm = this.formBuilder.group({
      amount: [{ value: '', disabled: true }, Validators.required],
      period: [{ value: '', disabled: true }, Validators.required],
      premiseId: [{ value: '', disabled: true }, Validators.required],
      description: [{ value: '', disabled: true }, Validators.required],
      propertyId: [{ value: '', disabled: true }, Validators.required],
      unitNumber: [{ value: '', disabled: true }],
      propertyName: [{ value: '', disabled: true }],
      obligationTypeId: [{ value: '', disabled: true }]
    });

    this.editObligationForm = this.formBuilder.group({
      amount: ['', Validators.required],
      period: ['', Validators.required],
      premiseId: ['', Validators.required],
      description: ['', Validators.required],
      unitNumber: [''],
      propertyName: [''],
      propertyId: [''],
      obligationTypeId: ['']
    });

    let premstr = localStorage.getItem('premise');

    this.selectedPremise = JSON.parse(premstr ? premstr : '');

    let propertystr = localStorage.getItem('property');

    this.property = JSON.parse(propertystr ? propertystr : '');

    if (this.action == 'create') {




      this.addObligationForm.setValue({
        amount: '', period: '', obligationTypeId: 1, description: 'maintenance payment due ' + 'LOT/APT ' + this.selectedPremise.unit_number,
        propertyName: this.property.name, propertyId: this.property.id,
        premiseId: this.selectedPremise.id, unitNumber: this.selectedPremise.unit_number
      });



    }

    if (this.action == 'view' || this.action == 'edit') {
      this.financialService.selectedMaintenance$.subscribe((value) => {
        if (!environment.production) {
          console.log('Maintenance selected: View ', value.length);
        }

        if (value.length == undefined) {
          let maintenancestr = localStorage.getItem('stmaintenance');
          this.maintenance = JSON.parse(maintenancestr ? maintenancestr : '');
        } else {
          this.maintenance = value;
        }
      });

      if (!environment.production) {
        console.log('Maintenance selected: View: After local storage: ', this.maintenance);
      }



      this.viewObligationForm.setValue({
        amount: this.maintenance.amount, period: moment(this.maintenance.period).format('MMMM-DD-YYYY'), obligationTypeId: this.maintenance.obligationTypeId, description: this.maintenance.description,
        propertyName: this.property.name, propertyId: this.property.id,
        premiseId: this.maintenance.premiseId, unitNumber: this.maintenance.premise.unit_number
      });

      this.editObligationForm.setValue({
        amount: this.maintenance.amount, period: this.maintenance.period, obligationTypeId: this.maintenance.obligationTypeId, description: this.maintenance.description,
        propertyName: this.property.name, propertyId: this.property.id,
        premiseId: this.maintenance.premiseId, unitNumber: this.maintenance.premise.unit_number
      });



    }


  }

  get foa() { return this.addObligationForm.controls; }
  get foe()  { return this.editObligationForm.controls; }

  appendDescription(event: any) {
    let temp = this.addObligationForm.value


    let month =  moment(temp.period).format('MMMM')
    let year =  moment(temp.period).format('YYYY')

    this.addObligationForm.controls['description'].setValue(temp.description = month + ' ' + year + ' ' + temp.description);

  }

  onEditMaintenance(): void {
    this.action = 'edit';
    this.router.navigate(['/manage-maintenance/edit']);
  }

  updateObligation(): void {
    if (!this.editObligationForm.valid) {
      if (!environment.production) {
        console.log('Form value: ',this.editObligationForm.value);
        console.log('Form not valid: ', this.editObligationForm);
      }

      return;
    }

    let updateformdata = this.editObligationForm.value;

    if (!environment.production) {
      console.log('Form data: Obligation Update: ', updateformdata);
    }
  }

  createObligation(): void {
    this.action = 'create';

    if (!this.addObligationForm.valid) {
      if (!environment.production) {
        console.log('Form value: ',this.addObligationForm.value);
        console.log('Form not valid: ', this.addObligationForm);
      }

      return;
    }

    let formdata = this.addObligationForm.value;

    if (!environment.production) {
      console.log('Form data: Obligation creation: ', formdata);
    }

    let request = {
      amount: formdata.amount,
      //period: formdata.period,
      period: moment(formdata.period).format('YYYY-MM-DD'),
      description: formdata.description,
      settled: false,
      premiseId: formdata.premiseId,
      propertyId: formdata.propertyId,
      obligationTypeId: formdata.obligationTypeId
    }

    console.log('Obligation request: ', request);
    this.financialService.createObligation(request).subscribe(
      {
        next: (data) => {
          if (!environment.production) {
            console.log('Obligation creation response: ', data);
          }
          this.toastr.success(data.message, 'Success');

          this.router.navigate(['/manage-premises/view'])
        },
        error: (error) => {
          this.toastr.error(error, 'Error');
          if (!environment.production) {
            console.log('Error: Obligation creation: ', error);
          }

        }
      }
    );
  }



  onEditObligation(): void {
    this.action = 'edit';
  }

  goBack(): void {
    this._location.back();
  }
}
