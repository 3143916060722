import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-notices',
  standalone: false,
  
  templateUrl: './manage-notices.component.html',
  styleUrl: './manage-notices.component.css'
})
export class ManageNoticesComponent {

}
