
<div class="col-md-6 offset-md-3 mt-5">

    <div class="ng-container change-password-logo">
        <img src="assets/login-logo.png">
    </div>
    <div class="card">
        <h5 class="card-header">Entrarit - Change Password</h5>
        <div class="card-body">
            <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label class="sr-only" for="password">Password</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="faKey"></fa-icon></div>
                      </div>
                      <input type="password" formControlName="password" class="form-control py-0" name="password" id="password"  placeholder="Password" [pattern]="passwordPattern"  [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" >
                      <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                        <div *ngIf="f['password'].errors['required']">Password is required</div>
                      </div>
                      <div *ngIf="f['password'] && f['password'].errors?.['pattern']" class="invalid-feedback">
                          Password has to be 5 or more characters.
                      </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="sr-only" for="confirmPassword">Password</label>
                    <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="faKey"></fa-icon></div>
                    </div>
                    <input type="password" formControlName="confirmPassword" class="form-control py-0" name="confirmPassword" id="confirmPassword"  placeholder="Confirm Password" [pattern]="passwordPattern"  [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }" >
                    <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
                        <div *ngIf="f['confirmPassword'].errors['required']">Please confirm password</div>
                    </div>
                    <div *ngIf="f['confirmPassword'] && f['confirmPassword'].errors?.['pattern']" class="invalid-feedback">
                        Confirm Password has to be 5 or more characters.
                    </div>
                    <div *ngIf="f['confirmPassword'] && f['confirmPassword'] && f['confirmPassword'] != f['password']" class="invalid-feedback">
                        Passwords do not match
                    </div>
                    </div>
                </div>
                <button type="button" (click)="cancel()" class="btn btn-warning">
                    Cancel  <fa-icon class="l-icon" [icon]="faHome"></fa-icon>
                  </button>

                  <button [disabled]="loading" class="btn btn-success float-right">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                      Submit <fa-icon class="l-icon" [icon]="faPaperPlane"></fa-icon>
                  </button>
            </form>
        </div>
    </div>
</div>

