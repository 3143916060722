import { Component, OnInit } from '@angular/core';
import { faKey, faHome, faPaperPlane, faSearch, faBell, faSignOutAlt,
  faTachometerAlt, faBuilding, faScaleBalanced, faGavel } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '../_services';
import { AuthResponse } from '../_models/responses';
import { Role } from '../_models/role';
import { PremiseAssociation } from '../_models/premise_association';
import { PremiseService } from '../_services/premise.service';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: false
})
export class HomeComponent implements OnInit {

  faBell = faBell;
  faSignOutAlt = faSignOutAlt;
  faSearch = faSearch;
  faGavel = faGavel;
  faScaleBalanced = faScaleBalanced;
  faTachometerAlt = faTachometerAlt;
  faBuilding = faBuilding;

  currentUser!: AuthResponse | null;
  premiseAssociations!: PremiseAssociation[];

  constructor(private authenticationService: AuthenticationService,
    private premiseService: PremiseService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
   this.currentUser = this.authenticationService.currentUserValue;
   console.log('Current user object::::', this.currentUser);

  }

  logout(): void {
    this.authenticationService.logout()
  }

  isAdmin() {
    //return this.currentUser;
    if (this.currentUser) {
        if (this.currentUser.access_token != undefined && this.currentUser.role != undefined) {
            return this.currentUser.role.includes(Role.ROLE_ADMIN);
        }
        else {
          return false;
        }

    }
    return false;
  }

  isUser() {
    //return this.currentUser;
    if (this.currentUser) {
        if (this.currentUser.access_token != undefined && this.currentUser.role != undefined) {
            return this.currentUser.role.includes(Role.ROLE_USER);
        }
        else {
          return false;
        }

    }
    return false;
  }


}
