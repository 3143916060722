import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params, TitleStrategy } from '@angular/router';
import { Property } from '../_models/property';
import { RoomConfig } from '../_models/room.config';
import { PremiseService } from '../_services/premise.service';
import { PropertyService } from '../_services/property.service';
import { environment } from '../../environments/environment';
import { faPlusCircle, faPaperPlane, faTimesCircle, faHashtag, faBed, faSearch, faCog, faBuilding,
        faHouse, faCircleArrowRight, faPenToSquare, faCircleArrowLeft, faEye, faCirclePlus, faHouseChimney} from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-manage-property',
    templateUrl: './manage-property.component.html',
    styleUrls: ['./manage-property.component.css'],
    standalone: false
})
export class ManagePropertyComponent {
  loading = false;

  searchProperty!: string;

  action: any;
  propertyid: any;

  advanced = false;
  fetching = false;

  addPropertyForm!: FormGroup;
  viewPropertyForm!: FormGroup;
  editPropertyForm!: FormGroup;

  addRoomConfigForm!: FormGroup;




  faPlusCircle = faPlusCircle;
  faHashTag = faHashtag;
  faBed = faBed;
  faBuilding = faBuilding;
  faHouse = faHouse;
  faSearch = faSearch;
  faPaperPlane = faPaperPlane;
  faTimesCircle = faTimesCircle;
  faCircleArrowRight = faCircleArrowRight;
  faCog = faCog;
  faEye = faEye;
  faPenToSquare = faPenToSquare;
  faCircleArrowLeft = faCircleArrowLeft;
  faCirclePlus = faCirclePlus;
  faHouseChimney = faHouseChimney;

  roomconfig: RoomConfig[] = [];

  properties: Property[] = [];

  submitRoomConfig = false;
  submitPropertyData = false;

  selectedPremises: any;

  property!: Property;

  constructor(private propertyService: PropertyService,
    private premiseService: PremiseService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
    ) { }


  onCreateProperty(): void {
    this.router.navigate(['/manage-property/create'])
  }

  onCancel(): void {
    this.router.navigate(['/manage-property']);
  }

  onEditSave(): void {


    if (this.editPropertyForm.invalid) {
      return;
    }

    let editpropertydata = this.editPropertyForm.value;

    let editpayload = {
      name: editpropertydata.property_name,
      property_type: editpropertydata.property_type,

    }

    this.propertyService.updateProperty(editpropertydata.property_id, editpayload).subscribe({
      next: (resp) => {
        this.toastr.success(resp.message, 'Success');
        if (!environment.production) {
          console.log('Property update: response: ', resp)
        }
        this.ngOnInit();
      },
      error: (error) => {
        this.toastr.error('Failed to update property.', 'Error');
        if (!environment.production) {
          console.log('Property update: failure response: ', error);
        }
      }
    });


    this.router.navigate(['/manage-property']);
  }

  viewPremises() {
    this.router.navigate(['/manage-premises/list']);
  }

  onSelectedProperty(property: any) {
    this.propertyService.setProperty(property);

    if (!environment.production) {
      console.log('Property selected: ', property);
      console.log('Action: ', this.action);
    }


      this.propertyService.selectedProperty$.subscribe((value) => {
        console.log('Property selected read: Property Component: ', value);
        if (value == undefined) {
          let propstr = localStorage.getItem('property');
          this.property = JSON.parse(propstr ? propstr : '');
        } else {
          this.property = value;
        }

      });


      this.viewPropertyForm.setValue({
        property_name: this.property.name, property_id: this.property.id,
        number_units: this.property.premises.length,
        property_type: this.property.property_type
      });

      this.router.navigate(['/manage-property/view']);


  }

  onEditProperty() {
    this.action = 'edit';
    this.propertyService.selectedProperty$.subscribe((value) => {
      console.log('Property read: Edit:  Property Component: ', value);
      this.property = value;
    });

    this.editPropertyForm.setValue({
      property_name: this.property.name, property_id: this.property.id,
      property_type: this.property.property_type
    });

    this.router.navigate(['/manage-property/edit']);
  }

  toggleAdvanced(): void {
    this.advanced = !this.advanced;
  }

  ngOnInit(): void {
    this.loading = true;

    console.log('NG init called: Manage properties ');
    console.log('Action: ', this.action);
    //this.roomconfig = [];
    //this.properties = [];

    this.route.params.subscribe((params: Params) => this.action = params['action']);
    this.route.params.subscribe((params: Params) => this.propertyid = params['id']);

    this.addRoomConfigForm = this.formBuilder.group({
      number_rooms: ['', Validators.required],
      unit_entitlement: ['', Validators.required]
    });

    this.addPropertyForm = this.formBuilder.group({
      property_name: ['', Validators.required],
      number_units: ['', Validators.required],
      property_type: ['', Validators.required],
      units_floor: ['']
    });

    this.editPropertyForm = this.formBuilder.group({
      property_id: ['', Validators.required],
      property_name: ['', Validators.required],
      property_type: ['', Validators.required],
    });

    this.viewPropertyForm = this.formBuilder.group({
      property_id: [{value: '', disabled: true}, Validators.required],
      property_name: [{value: '', disabled: true}, Validators.required],
      number_units: [{value: '', disabled: true}, Validators.required],
      property_type: [{value: '', disabled: true}, Validators.required],
    });

    //if (this.action != 'create') {
      this.propertyService.getAllProperties().subscribe(
        {
          next: (properties) => {
            this.properties = properties;
            if (!environment.production) {
              console.log('Property data: ', this.properties);
            }

            if (this.action != 'create') {
              if (this.properties.length == 0) {

                //this.properties = Prop[];
              } else if (this.properties.length == 1) {

                this.propertyService.setProperty(this.properties[0]);
                this.viewProperty();
              } else {

                this.router.navigate(['/manage-property/list']);
              }
            }



          },
          error: (error) => {
            if (!environment.production) {
              console.log('Fail to load property data: ', error);
            }

          }
        }
      );
    //}



  }

  onDeleteRoomConfig(obj: any): void {
    this.roomconfig = this.roomconfig.filter(item => item !== obj)
  }

  onAddRoomConfig(): void {
    this.submitRoomConfig = true;
    if (!this.addRoomConfigForm.valid) {
      return;
    }
    let roomconfigdata = this.addRoomConfigForm.value;



    this.roomconfig.push(roomconfigdata);

    this.addRoomConfigForm.reset();
    this.submitRoomConfig = false;

    if (!environment.production) {
      console.log('Room config data submitted: ', roomconfigdata);
    }
  }

  get fr() { return this.addRoomConfigForm.controls; }

  get fp() { return this.addPropertyForm.controls; }

  //createProperty(name: string, proptype: string, units: number, unitsfloor: number) {
  createProperty() {
    this.submitPropertyData = true;
    this.fetching = true;
    if (!this.addPropertyForm.valid) {
      if (!environment.production) {
        console.log('Form not valid: ', this.addPropertyForm);
      }
      this.fetching = false;
      return;
    }


    let formdata = this.addPropertyForm.value;

    if (!environment.production) {
      console.log('Form data: Property creation: ', formdata);
    }

    let name = formdata.property_name;
    let proptype = formdata.property_type
    let units = formdata.number_units;
    let unitsfloor = formdata.units_floor;

    const property_name = name;
    const number_of_units = units;

    let larger_config: RoomConfig;
    let smaller_config: RoomConfig;

    let premises = [];
    this.properties = [];

    for (let index = 1; index <= units; index++) {
      if (number_of_units == unitsfloor && this.roomconfig.length > 0) {
        premises.push({
          unit_number: index,
          unit_entitlement: 1,
          number_rooms: this.roomconfig[0].number_rooms
        });
      } else if (this.roomconfig.length == 0) {
        premises.push({
          unit_number: index,
          unit_entitlement: 1,
          number_rooms: 1
        });
      } else {
        let modulo = index % unitsfloor;

        //this.roomconfig.filter(t => t.number_rooms == )
        let num_room_config = this.roomconfig.length;

        if (num_room_config == 2) {

          if (this.roomconfig[0].number_rooms > this.roomconfig[1].number_rooms) {
            larger_config = this.roomconfig[0];
            smaller_config = this.roomconfig[1];
          } else {
            larger_config = this.roomconfig[1];
            smaller_config = this.roomconfig[0];
          }

          premises.push({
            unit_number: index,
            unit_entitlement: (modulo == 1 || modulo == 0) ? larger_config.unit_entitlement : smaller_config.unit_entitlement,
            number_rooms: (modulo ==  1 || modulo == 0) ? larger_config.number_rooms : smaller_config.number_rooms

          });
        }


      }


    }

    let request = {
      id: 0,
      name: property_name,
      property_type: proptype,
      premises: premises
    }

    console.log('Property request: ', request);
    this.propertyService.createProperty(request).subscribe(
      {
        next: (data) => {
          if (!environment.production) {
            console.log('Property creation response: ', data);
          }
          this.toastr.success(data.message, 'Success');
          this.action = 'list';
          request.id = data.id ? data.id : 0 ;
          this.properties.push(request);
          console.log('After property id update: ', request);
          this.ngOnInit();
          this.router.navigate(['/manage-property'])
        },
        error: (error) => {

        }
      }
    );


    this.submitPropertyData = false;
    this.fetching = false;
  }

  viewProperty() {

    this.propertyService.selectedProperty$.subscribe((value) => {
      console.log('Property selected read: Property Component: ', value);
      this.property = value;
    });


    this.viewPropertyForm.setValue({
      property_name: this.property.name, property_id: this.property.id,
      number_units: this.property.premises.length,
      property_type: this.property.property_type
    });

    this.router.navigate(['/manage-property/view']);
  }

  editProperty(id: number) {
    let propertyobject;

    propertyobject = this.properties.find(p => p.id == id);


    this.editPropertyForm.setValue({
      property_name: propertyobject?.name, property_id: propertyobject?.id,
      property_type: propertyobject?.property_type, premises: propertyobject?.premises
    });
    this.router.navigate(['/manage-property/edit/' + id]);
  }

}
