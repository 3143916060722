<div class="container">
    <div *ngIf="action == 'index'">
        <div class="d-flex justify-content-between align-items-center">
            <h1 class="fs-2 fw-light mt-4">Choose Property</h1>
            <button (click)="goBack()" class="btn btn-primary">Back <fa-icon [icon]="faCircleArrowLeft"></fa-icon></button>
        </div>

        <div class="form-group has-search">
            <fa-icon class="form-control-feedback" [icon]="faSearch"></fa-icon>
            <input type="text" name="search" [(ngModel)]="searchProperty" class="form-control" placeholder="Search by property name">
        </div>
    
        <ul class="list-group list-group-flush col-md-6">
            <li (click)="onSelectedProperty(property)" class="fs-3 text-primary list-group-item list-group-item-action d-flex justify-content-between align-items-center" 
            *ngFor="let property  of properties | filter: searchProperty ">
                <fa-icon *ngIf="property.property_type == 'house'" class="l-icon" [icon]="faHouse"></fa-icon> 
                <fa-icon *ngIf="property.property_type == 'strata'" class="l-icon" [icon]="faBuilding"></fa-icon> 
                <span class="text-black fw-lighter">{{property.name}} </span>
                <fa-icon [icon]="faCircleArrowRight"></fa-icon>
            </li>
    
        </ul>
    </div>
    <div *ngIf="action == 'view'">
        <div *ngIf="pageLoading === true" class="d-flex justify-content-center"> 
            <div class="spinner-border spinner-border-special mt-4" role="status"> 
                <span class="sr-only" id="loading"></span> 
                
            </div> 
            <p class="loadingtext justify-content-center position-absolute top-25">Loading data</p>
        </div> 
        
        <mat-tab-group *ngIf="pageLoading === false">
            
            <mat-tab label="All Data"> 
                <div id="content" #content>
                    <h1 class="fs-2 fw-light mt-4">Compliance - Premises List</h1>
                    <div class="d-inline-block mt-4 ">
                        <button (click)="printScreen()" class="btn btn-info mx-2 d-print-none"><fa-icon [icon]="faPrint"></fa-icon></button>
                        <button [disabled]="generatingfile" (click)="downloadScreen(property.name)" class="btn btn-info mx-2 d-print-none">
                          <fa-icon *ngIf="!generatingfile" [icon]="faDownload"></fa-icon>
                          <span *ngIf="generatingfile" class="spinner-border spinner-border-sm mr-1"></span>
                        </button>
    
                    </div>
                        <!-- <button (click)="viewStatement()" class="btn btn-info mx-2 d-print-none"><fa-icon [icon]="faFloppyDisk"></fa-icon></button> -->
                  
                    <div class="form-group my-4">
                            
                        <label class="form-label" for="property_name">Property Name</label>
                        <input class="form-control col-3"  type="text" placeholder="Property name" [value]="property.name" readonly />
                    </div>
                    <table class="table table-striped table-bordered">
                        <thead class="py-1">
                          <th scope="col">APT/LOT# <button class="btn btn-sm btn-link d-print-none" (click)="toggleSort()"><fa-icon [icon]="faSort"></fa-icon></button></th>
                          <th scope="col"># Months Outstanding</th>
                          <th scope="col">Last Maintenance Paid</th>
                          <th scope="col">Total Maintenance Paid <button class="btn btn-sm btn-link d-print-none" (click)="togglePSort()"><fa-icon [icon]="faSort"></fa-icon></button></th>
                          <th scope="col">Total Maintenance Outstanding <button class="btn btn-sm btn-link d-print-none" (click)="toggleMSort()"><fa-icon [icon]="faSort"></fa-icon></button></th>
                          <!-- <th class="d-print-none" scope="col">Option(s)</th> -->
                        </thead>
                        <tbody>
                            <tr *ngFor="let premise of complianceList | orderBy: 'unit_number' : sortOrder  ">
                                <td>{{ premise.unit_number }}</td>
                                <td>{{ premise.totalMonthsOutstanding }}</td>
                                <td *ngIf="premise.lastmaintenancepayment != undefined">{{ premise.lastmaintenancepayment!.period! | date: 'MMM-y':'UTC' }}</td> 
                                <td *ngIf="premise.lastmaintenancepayment == undefined">No data</td> 
                                <td>{{ premise.totalmaintenancepaid | currency }}</td>
                                <td>{{ premise.totalmaintenanceoutstanding | currency }}</td>
                                <!-- <td><button class="btn btn-primary">View </button></td> -->
                            </tr>
                         
                        </tbody>
                  
                      </table>
                </div>
                

            </mat-tab>
            <mat-tab label="By Month"> 
                <h1 class="fs-2 fw-light mt-4">Compliance - By Month</h1>
                <div class="form-group my-4">
                        
                    <label class="form-label" >Year/Month Selected</label>
                    <input class="form-control col-3"  type="text" placeholder="Property name" value='Feb-2024' readonly />
                </div>
                <div class="form-group my-4">
                        
                    <label class="form-label" >Property Name</label>
                    <input class="form-control col-3"  type="text" placeholder="Property name" [value]="property.name" readonly />
                </div>

            </mat-tab>
            <mat-tab label="By Year"> 
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="fs-2 fw-light mt-4">Compliance - Numbers</h1>
                    <button (click)="goBack()" class="btn btn-primary">Back <fa-icon [icon]="faCircleArrowLeft"></fa-icon></button>
                </div>
                <form [formGroup]="complianceDataForm">
                    <div class="form-group my-4">
                        
                        <label class="form-label" for="property_name">Property Name</label>
                        <input class="form-control col-3"  type="text" placeholder="Property name" formControlName="property_name" />
                    </div>
            
                    <div class="form-group">
                        <label class="form-label">Property Type</label>
                        <select class="form-control" formControlName="property_type" >
                            <option value="strata">Strata</option>
                            <option value="house">House</option>
                            <option value="hotel">Hotel</option>
                        </select>
                    </div>
                    <div class="form-group my-4">
                        <label class="form-label" for="number_units">Number of units</label>
                        <input class="form-control" placeholder="Number of units/apartments" formControlName="number_units" />
                    </div>
            
                    <div class="form-group my-4">
                        <label class="form-label" for="number_units">Number of Units Compliant YTD</label>
                        <input class="form-control" placeholder="Number of units/apartments" formControlName="compliantnumberytd" readonly/>
                    </div>
                    
                    <div class="form-group my-4">
                        <label class="form-label" for="number_units">Number of Units Compliant Current Period</label>
                        <input class="form-control" placeholder="Number of units/apartments" formControlName="currentnumbercompliant"  readonly/>
                    </div>
                
                    <div class="form-group my-4">
                        <label class="form-label" for="number_units">Percentage Compliance</label>
                        <input class="form-control" placeholder="Number of units/apartments" formControlName="percentageytd"  readonly/>
                    </div>
                </form>
                
            
                
            
            
            
                
            
                <h1 class="fs-2 fw-light mt-4">Compliance - Charts</h1>
                <div class="row my-4">
                    <canvas baseChart
                    [type]="'pie'"
                    [datasets]="pieChartDatasets"
                    [labels]="pieChartLabels"
                    [options]="pieChartOptions"
                    [plugins]="pieChartPlugins"
                    [legend]="pieChartLegend">
                  </canvas>
                </div>
                <div class="row my-4">
                    <canvas baseChart
                    [data]="barChartData"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="barChartLegend"
                    [type]="'bar'">
                    </canvas>
                </div>
                <div class="row my-4">
                    <canvas baseChart width="400" height="400"
                    [type]="'line'"
                    [data]="lineChartData"
                    [options]="lineChartOptions"
                    [legend]="lineChartLegend">
                  </canvas>
                </div>
        

            </mat-tab>
        </mat-tab-group>

            
    </div>
    



</div>

