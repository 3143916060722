import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-breach',
  standalone: false,
  
  templateUrl: './manage-breach.component.html',
  styleUrl: './manage-breach.component.css'
})
export class ManageBreachComponent {

}
