import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Compliance } from "../_models/compliance";
import { Maintenance } from "../_models/maintenance";
import { Mortgage } from "../_models/mortgage";
import { Obligation } from "../_models/obligation";
import { Payment } from "../_models/payment";
import { MessageResponse } from "../_models/responses";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";


@Injectable({ providedIn: 'root' })
export class FinancialService {

    private payment$ = new BehaviorSubject<any>({});
    selectedPayment$ = this.payment$.asObservable();

    private maintenance$ = new BehaviorSubject<any>({});
    selectedMaintenance$ = this.maintenance$.asObservable();

    private fee$ = new BehaviorSubject<any>({});
    selectedFee$ = this.fee$.asObservable();

    private mortgage$ = new BehaviorSubject<any>({});
    selectedMortgage$ = this.mortgage$.asObservable();

    constructor(private http: HttpClient) {

    }


    setPayment(payment: any) {
        this.payment$.next(payment);
        localStorage.setItem('stpayment', JSON.stringify(payment));
    }

    setFee(fee: any) {
        this.fee$.next(fee);
        localStorage.setItem('stfee', JSON.stringify(fee));
    }

    setObligation(maintenance: any) {
        this.maintenance$.next(maintenance);
        localStorage.setItem('stmaintenance', JSON.stringify(maintenance));
    }

    setMortgage(mortgage: any) {
        this.mortgage$.next(mortgage);
        localStorage.setItem('stmortgage', JSON.stringify(mortgage));
    }

    getObligations(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<Obligation[]>(`${environment.apiUrl}/api/obligation/premises`, request, { headers: header })
    }


    createObligation(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<MessageResponse>(`${environment.apiUrl}/api/obligation`, request, { headers: header })
    }

    createPayment(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<MessageResponse>(`${environment.apiUrl}/api/payment`, request, { headers: header })
    }

    updateMortgage(mortgageid: number, request: any) {
        return this.http.put<MessageResponse>(`${environment.apiUrl}/api/mortgage/` + mortgageid, request);
    }

    deletePayment(paymentid: number) {
        return this.http.delete<MessageResponse>(`${environment.apiUrl}/api/payment/` + paymentid);
    }

    fetchCompliance(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<Obligation[]>(`${environment.apiUrl}/api/compliance`, request, { headers : header});
    }

    fetchComplianceList(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<Compliance[]>(`${environment.apiUrl}/api/compliance/list`, request, { headers : header});
    }

    fetchUnpaidMaintenance(propertyid: number, premiseid: number) {
        return this.http.get<Maintenance[]>(`${environment.apiUrl}/api/maintenance/unpaid/` + propertyid + "/" + premiseid);
    }

    getFees(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<Obligation[]>(`${environment.apiUrl}/api/obligation/premises`, request, { headers : header});
    }


    getPayments(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<Payment[]>(`${environment.apiUrl}/api/payment/premises`, request, { headers : header});
    }

    getPayment(paymentid: number) {
        return this.http.get<Payment>(`${environment.apiUrl}/api/payment/` + paymentid);
    }

    getMortgage(mortgageid: number) {
        return this.http.get<Mortgage>(`${environment.apiUrl}/api/mortgage/` + mortgageid);
    }

    getObligation(obligationid: number) {
        return this.http.get<Obligation>(`${environment.apiUrl}/api/obligation/` + obligationid);
    }
}
