import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Property } from '../_models/property';
import { MessageResponse } from '../_models/responses';
import { BehaviorSubject } from 'rxjs';
import { provideNoopAnimations } from '@angular/platform-browser/animations';

@Injectable({ providedIn: 'root' })
export class PropertyService {
    private property$ = new BehaviorSubject<any>({});
    selectedProperty$ = this.property$.asObservable();

    constructor(private http: HttpClient) { }

    setProperty(property: any) {
        this.property$.next(property);
        localStorage.setItem('property', JSON.stringify(property));
    }

    getAllProperties() {
        return this.http.get<Property[]>(`${environment.apiUrl}/api/property`);
    }

    createProperty(request: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<MessageResponse>(`${environment.apiUrl}/api/property`, request, { headers : header});
    }

    updateProperty(propertyid: number, request: any) {
        return this.http.put<MessageResponse>(`${environment.apiUrl}/api/property/`+propertyid, request);
    }


}
