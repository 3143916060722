<div class="container">
  <router-outlet></router-outlet>
</div>

<div class="footer text-center mt-4 d-print-none">
  <p>
      &copy; Billdeo 2023 - {{ currentyear }}
      <a href="https://premises.billdeo.com" target="_top">App Website </a> v {{ VNUM }}
  </p>
</div>




