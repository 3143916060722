import { Component } from '@angular/core';

@Component({
  selector: 'app-statement',
  standalone: false,
  
  templateUrl: './statement.component.html',
  styleUrl: './statement.component.css'
})
export class StatementComponent {

}
