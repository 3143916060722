import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { AppComponent } from './app.component';
import { BreachesComponent } from './breaches/breaches.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChoosePropertyComponent } from './choose-property/choose-property.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LawsComponent } from './laws/laws.component';
import { ManageBreachComponent } from './manage-breach/manage-breach.component';
import { ManageFeesComponent } from './manage-fees/manage-fees.component';
import { ManageLawComponent } from './manage-law/manage-law.component';
import { ManageMaintenanceComponent } from './manage-maintenance/manage-maintenance.component';
import { ManageMortgageComponent } from './manage-mortgage/manage-mortgage.component';
import { ManageNoticesComponent } from './manage-notices/manage-notices.component';
import { ObligationComponent } from './obligation/obligation.component';
import { PaymentComponent } from './payment/payment.component';
import { PremisesComponent } from './premises/premises.component';
import { QueryMaintenanceComponent } from './query-maintenance/query-maintenance.component';
import { RegistrationComponent } from './registration/registration.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { StatementComponent } from './statement/statement.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { ManagePropertyComponent } from './manage-property/manage-property.component';
import { ManagePremisesComponent } from './manage-premises/manage-premises.component';
import { NoticesComponent } from './notices/notices.component';
import { ManagePaymentsComponent } from './manage-payments/manage-payments.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSearchFilterModule } from 'ngx-search-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher, MatNativeDateModule } from '@angular/material/core';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PercentPipe, CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { OrderByPipe } from './_helpers/order.pipe';
import { TouchedErrorStateMatcher } from './_helpers/touched-error-state.matcher';
import { ToastrModule } from 'ngx-toastr';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HomeComponent } from './home/home.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BreachesComponent,
    ChangePasswordComponent,
    ChoosePropertyComponent,
    ComplianceComponent,
    DashboardComponent,
    ForgetPasswordComponent,
    HomeComponent,
    LawsComponent,
    ManageBreachComponent,
    ManageFeesComponent,
    ManageLawComponent,
    ManageMaintenanceComponent,
    ManageMortgageComponent,
    ManageNoticesComponent,
    ObligationComponent,
    OrderByPipe,
    PaymentComponent,
    PremisesComponent,
    QueryMaintenanceComponent,
    RegistrationComponent,
    SplashScreenComponent,
    StatementComponent,
    VerifyAccountComponent,
    ManagePropertyComponent,
    ManagePremisesComponent,
    NoticesComponent,
    ManagePaymentsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgxSearchFilterModule,
    BaseChartDirective,
    NgxPaginationModule,
        FormsModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatTabsModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        MatDialogModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
          positionClass :'toast-top-right'
        })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorStateMatcher, useClass: TouchedErrorStateMatcher },
    PercentPipe, CurrencyPipe, OrderByPipe,
    provideCharts(withDefaultRegisterables()),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
