import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageResponse } from '../_models/responses';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  sendNotification(request: any) {
    let header = new HttpHeaders();
    header = header.append('content-type', 'application/json');

    return this.http.post<MessageResponse>(`${environment.apiUrl}/api/notifications/notify`, request, { headers : header });
  }
}
