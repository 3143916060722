import { Component, OnInit } from '@angular/core';
import { Property } from '../_models/property';
import { faSearch, faArrowRight, faHouse, faBuilding, faPaperPlane, faMapPin, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { PropertyService } from '../_services/property.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-query-maintenance',
    templateUrl: './query-maintenance.component.html',
    styleUrls: ['./query-maintenance.component.css'],
    standalone: false
})
export class QueryMaintenanceComponent implements OnInit {

  loading = false;
  fetching = false;

  searchText!: string;

  faSearch = faSearch;
  faArrowRight = faArrowRight;
  faHouse = faHouse;
  faPaperPlane = faPaperPlane;
  faMapPin = faMapPin
  faBuilding = faBuilding;
  faSpinner = faSpinner;

  properties!: Property[];

  constructor(private propertyService: PropertyService) { }

  ngOnInit(): void {
    this.loading = true;

    // this.propertyService.getAllProperties().pipe(first()).subscribe(
    //   {
    //     next: (properties) => {
    //       this.properties = properties;
    //       if (!environment.production) {
    //         console.log('Property data: ', this.properties);
    //       }

    //     },
    //     error: (error) => {
    //       if (!environment.production) {
    //         console.log('Fail to load property data: ', error);
    //       }

    //     }
    //   }
    // )
  }

  queryMaintenance(): void {
    this.fetching = true;

    //this.fetching = false;
  }

  loadRequestPage(property: any) {

  }


}
