import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Property } from '../_models/property';
import { FinancialService } from '../_services/financial.service';
import { PropertyService } from '../_services/property.service';
import { environment } from '../../environments/environment';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { faPlusCircle, faPaperPlane, faTimesCircle, faHashtag, faBed, faSearch, faCog, faBuilding,
  faHouse, faCircleArrowRight, faPenToSquare, faCircleArrowLeft, faDownload, faPrint, faEye, faSort, faCirclePlus} from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Location, PercentPipe } from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import { Compliance } from '../_models/compliance';
import moment from 'moment';
import Chart from 'chart.js/auto';
import $ from 'jquery';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
    selector: 'app-compliance',
    templateUrl: './compliance.component.html',
    styleUrls: ['./compliance.component.css'],
    standalone: false
})
export class ComplianceComponent {
  action: any;
  searchProperty: any;

  pageLoading =  false;
  generatingfile = false;

  complianceDataForm!: FormGroup;
  property!: Property;
  properties: Property[] = [];
  complianceList!: Compliance[];

  sortOrder: "asc" | "desc" = "asc";
  msortOrder: "desc" | "asc" = "desc";
  psortOrder: "desc" | "asc" = "desc";

  faPlusCircle = faPlusCircle;
  faHashTag = faHashtag;
  faBed = faBed;
  faBuilding = faBuilding;
  faHouse = faHouse;
  faSort = faSort;
  faDownload = faDownload;
  faPrint = faPrint;
  faSearch = faSearch;
  faPaperPlane = faPaperPlane;
  faTimesCircle = faTimesCircle;
  faCircleArrowRight = faCircleArrowRight;
  faCog = faCog;
  faEye = faEye;
  faPenToSquare = faPenToSquare;
  faCircleArrowLeft = faCircleArrowLeft;
  faCirclePlus = faCirclePlus;

  compliancedata!: any;

  constructor(private route: ActivatedRoute, private percentPipe: PercentPipe,
    private _location: Location, private formBuilder: FormBuilder,
    private toastr: ToastrService, private financialService: FinancialService,
    private router: Router, private propertyService: PropertyService,
 ) {

 }

 ngOnInit(): void {
  this.route.params.subscribe((params: Params) => this.action = params['action']);


  this.complianceDataForm = this.formBuilder.group({
    property_id: [{value: '', disabled: true}, Validators.required],
    property_name: [{value: '', disabled: true}, Validators.required],
    number_units: [{value: '', disabled: true}, Validators.required],
    property_type: [{value: '', disabled: true}, Validators.required],
    percentageytd: ['', Validators.required],
    currentnumbercompliant: ['', Validators.required],
    compliantnumberytd: ['', Validators.required],

  });

  //fetch data from api



  this.propertyService.getAllProperties().subscribe(
    {
      next: (properties) => {
        this.properties = properties;
        if (!environment.production) {
          console.log('Property data: Compliance:: ', this.properties);
        }

        if (this.action != 'create') {
          if (this.properties.length == 0) {
            console.log('No property found');
            //this.properties = Prop[];
          } else if (this.properties.length == 1) {
            if (!environment.production) {
              console.log('1 Property found', this.properties);
            }
            this.propertyService.setProperty(this.properties[0]);
            this.viewPropertyCompliance();
          } else {
            this.action = 'index';
            //this.router.navigate(['/compliance/listproperties']);
          }
        }


        //this.property = this.properties[0];


      },
      error: (error) => {
        if (!environment.production) {
          console.log('Fail to load property data: Compliance', error);
          }

        }
      }
    );
  }

  // Pie
  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  //public pieChartLabels = [ [ 'Compliant' ], [ 'Non', 'Compliant', ] ];
  public pieChartLabels = [ [ 'Compliant' ], [ 'Non-Compliant', ] ];
  public pieChartDatasets = [ {
    data: [ 13, 163 ]
  } ];
  public pieChartLegend = true;
  public pieChartPlugins = [

  ];

  // Bar chart

  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [ 'Jan-2023', 'Feb-2023', 'March-2023', 'April-2023' ],
    datasets: [
      { data: [ 8, 10, 13, 12 ], label: 'Compliant' },
      { data: [ 178, 176, 163, 164 ], label: 'Non-Compliant' }
    ]
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: false,
  };

  //Line chart data

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [
      'Jan-2023',
      'Feb-2023',
      'Mar-2023',
      'Apr-2023',
    ],
    datasets: [
      {
        data: [ 8, 10, 13, 12 ],
        label: 'Compliance - Each month',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)'
      }
    ]
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false
  };
  public lineChartLegend = true;






 viewPropertyCompliance(): void {
  this.action = 'view';
  let propstr = localStorage.getItem('property');

  this.property = JSON.parse(propstr ? propstr : '');

  let request = {
    propertyid: this.property.id
  }

  this.pageLoading = true;

  this.financialService.fetchCompliance(request).subscribe({
    next: (result) => {
      if (!environment.production) {
        console.log('Compliance data: Fetched:: ', result);
      }
      this.compliancedata = result;
    },
    error: (error) => {
      if (!environment.production) {
        console.log('Compliance : Error: ', error);
      }
    }


  })

  this.financialService.fetchComplianceList(request).subscribe({
    next: (result) => {
      if (!environment.production) {
        console.log('Compliance List Data: Fetched:: ', result);
      }
      this.complianceList = result;
      this.pageLoading = false;
      //document.getElementById('loading').style.display = 'none';
      $('#loading').css('display','none');
    },
    error: (error) => {
      if (!environment.production) {
        console.log('Compliance List : Error: ', error);
      }
      this.pageLoading = false;
      //document.getElementById('loading').style.display = 'none';
      $('#loading').css('display','none');
    }


  })

  //this.compliancedata.totalgeneratedbyperiod.sort((a, b) => moment(b.period).unix() - moment(a.period).unix());

  //let compliance_current_period = 12/14;
  //let compliance_ytd = this.compliancedata.totalmaintenancepaid.totalunits_paid / this.compliancedata.totalmaintenancegenerate.total_maintenance_billable


  this.complianceDataForm.setValue({
    property_id: this.property.id, property_name: this.property.name, number_units: this.property.premises.length, property_type: this.property.property_type,
    percentageytd: this.percentPipe.transform(0.13, '1.2' ), currentnumbercompliant: 12, compliantnumberytd: 10
  });




  //this.pieChartLabels = [['Hello', 'World']]


 }



 goBack(): void {
  this._location.back();
 }

 printScreen() {
  window.print();
}


downloadScreen(propertyname: string) {
  this.generatingfile = true;
  const div = document.getElementById('content')!;
  const options = {
    background: 'white',
    scale: 3,
    ignoreElements: (element: Element) => {
      return element.classList.contains('d-print-none') ? true : false;
    }

  };
  html2canvas(div, options).then((canvas) => {

    var img = canvas.toDataURL("image/PNG");
    var doc = new jsPDF('p', 'mm', 'a4');

    // Add image Canvas to PDF
    const bufferX = 5;
    const bufferY = 5;
    const imgProps = (<any>doc).getImageProperties(img);
    const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

    return doc;
  }).then((doc) => {
    let filename = 'compliance' + '_' + propertyname + '_' + moment().format('MMMDDYYYY') + '.pdf';
    doc.save(filename);
    this.generatingfile = false;
  });

}

 toggleSort(): void {
  this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
}

togglePSort(): void {
  this.psortOrder = this.psortOrder === 'desc' ? 'asc': 'desc'
}

toggleMSort(): void {
  this.msortOrder = this.msortOrder === 'desc' ? 'asc': 'desc'
}

 onSelectedProperty(property: any) {
  this.propertyService.setProperty(property);
  this.viewPropertyCompliance();
 }

}
