import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService, AuthenticationService } from '../_services';
import { environment } from '../../environments/environment';
import { faPaperPlane, faUser, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faPhoneAlt, faKey, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { Registration } from '../interfaces/request.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css'],
    standalone: false
})

export class RegistrationComponent implements OnInit {

  registrationForm! : FormGroup;
  loading = false;
  submitted = false;
  error = '';

  faPaperPlane = faPaperPlane;
  faArrowLeft = faArrowLeft;
  faPhoneAlt = faPhoneAlt;
  faUser = faUser;
  faEnvelope = faEnvelope;
  faKey = faKey;
  faMapMarkedAlt = faMapMarkedAlt;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  passwordPattern = "^[a-zA-Z0-9@!#$*]{5,30}$";
  fullNamePattern = "^[A-Za-z -]{5,40}[A-Za-z -]+";
  namePattern = "([a-zA-Z0-9_\s]+)"
  usernamePattern = '[a-zA-Z][a-zA-Z0-9-_]{2,30}';
  addressPattern = "^[A-Za-z0-9 -,-]+[A-Za-z0-9,]+";
  lotPattern = "^[0-9A-Za-z]{1,5}";

  constructor(        private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private _location: Location,
    private userService: UserService,
    private authenticationService: AuthenticationService) { }



  ngOnInit() {

    this.registrationForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      phone: ['', [Validators.required, Validators.minLength(10)]],
      password: ['', [Validators.required,  Validators.minLength(5), Validators.pattern(this.passwordPattern)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(5), Validators.pattern(this.passwordPattern)]]
    });
  }

  goBack() {
    this._location.back();
  }

  get f() { return this.registrationForm.controls; }

  get userEmail() {
    return this.registrationForm.get('email');
  }

  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
    }
  }

  // getting the form control elements
  get password(): AbstractControl {
    return this.registrationForm.controls['password'];
  }

  get confirm_password(): AbstractControl {
    return this.registrationForm.controls['confirmPassword'];
  }

  onSubmit() {
    this.submitted = true;

    if (!environment.production) {
      console.log('Is form invalid: ', this.registrationForm.invalid);
      console.log('Form object: ', this.registrationForm);
    }

    if (this.registrationForm.invalid)
    {
        this.error = 'Please fill out form properly';
        return;
    }

    var data = this.registrationForm.value;
    let reqjson: Registration =  {
      firstname: data.firstname,
      lastname: data.lastname,
      username: data.username,
      phone: data.phone,
      email: data.email,
      password: data.password
    };

    this.loading = true;

    this.userService.registerUser(reqjson).subscribe({
      next: (resp) => {
        this.toastr.success(resp.message, 'Success',);
        this.loading = false;
        this.router.navigate(['/login'])
      },
      // complete: () => {
      //   this.router.navigate(['/login']);
      // },
      error: (error) => {
        this.toastr.error(error, 'Error');
        this.loading = false;
      }
    });

  }

}
