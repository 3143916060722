import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { MessageResponse } from '../_models/responses';
import { UserProfile } from '../_models/user_profile';
import { Person } from '../_models/person';


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getPerson(premiseId: number, propertyId: number) {
        return this.http.get<Person>(`${environment.apiUrl}/api/person/premises/`+premiseId)
    }

    getUserProfile(username: string) {
        return this.http.get<UserProfile>(`${environment.apiUrl}/api/userprofile/` + username);
    }

    getAllUserProfiles() {
        return this.http.get<UserProfile[]>(`${environment.apiUrl}/api/userprofiles/list`);
    }

    registerUser(formdata: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<MessageResponse>(`${environment.apiAuthUrl}/register`, formdata, { headers: header});
    }

    requestPasswordReset(formdata: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<MessageResponse>(`${environment.apiAuthUrl}/password/reset`, formdata, { headers: header });
    }

    requestUsernameDetails(formdata: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<MessageResponse>(`${environment.apiUrl}/api/username/query`, formdata, { headers: header });
    }

    updateUserRole(formdata: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');
        return this.http.post<MessageResponse>(`${environment.apiUrl}/api/user/property/role`, formdata, { headers : header });
    }

    changePassword(formdata: any) {
        let header = new HttpHeaders();
        header = header.append('content-type', 'application/json');

        return this.http.post<MessageResponse>(`${environment.apiAuthUrl}/api/password/change`, formdata, {headers: header});
    }


}
