import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreachesComponent } from './breaches/breaches.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LawsComponent } from './laws/laws.component';
import { ManageBreachComponent } from './manage-breach/manage-breach.component';
import { ManageFeesComponent } from './manage-fees/manage-fees.component';
import { ManageLawComponent } from './manage-law/manage-law.component';
import { ManageMaintenanceComponent } from './manage-maintenance/manage-maintenance.component';
import { ManageMortgageComponent } from './manage-mortgage/manage-mortgage.component';
import { PremisesComponent } from './premises/premises.component';
import { QueryMaintenanceComponent } from './query-maintenance/query-maintenance.component';
import { RegistrationComponent } from './registration/registration.component';
import { StatementComponent } from './statement/statement.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { AuthGuard } from './_helpers/auth.guard';
import { Role } from './_models/role';
import { ManagePropertyComponent } from './manage-property/manage-property.component';
import { ManagePremisesComponent } from './manage-premises/manage-premises.component';
import { NoticesComponent } from './notices/notices.component';
import { ManagePaymentsComponent } from './manage-payments/manage-payments.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    component: HomeComponent,
    path: "home",
    canActivate: [AuthGuard]
  },
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "query-maintenance",
    component: QueryMaintenanceComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard]
  },
  {
    path: "statement",
    component: StatementComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard]
  },
  {
    path: "manage-property/:action",
    component: ManagePropertyComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "manage-mortgage/:action",
    component: ManageMortgageComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard]
  },
  {
    path: "manage-premises/:action",
    component: ManagePremisesComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "manage-property",
    component: ManagePropertyComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
    pathMatch: "full"
  },
  {
    path: "compliance",
    //redirectTo: "compliance/index",
    component: ComplianceComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
    pathMatch: "full"
  },
  {
    path: "manage-breaches/:action",
    component: ManageBreachComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard]
  },
  {
    path: "breaches",
    component: BreachesComponent,
    //data: { roles: [Role.ROLE_USER]},
    //canActivate: [AuthGuard]
  },
  {
    path: "manage-laws/:action",
    component: ManageLawComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard]
  },
  {
    path: "laws",
    component: LawsComponent,
    data: { roles: [Role.ROLE_USER]},
    canActivate: [AuthGuard]
  },
  {
    path: "manage-fees/:action",
    component: ManageFeesComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "premises/:action",
    component: PremisesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "premises",
    component: PremisesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "notices",
    component: NoticesComponent,
    canActivate: [AuthGuard]

  },
  {
    path: "manage-payments/:action",
    component: ManagePaymentsComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "manage-maintenance/:action",
    component: ManageMaintenanceComponent,
    data: { roles: [Role.ROLE_ADMIN]},
    canActivate: [AuthGuard],
  },
  {
    path: "compliance/:action",
    data: { roles: [Role.ROLE_ADMIN]},
    component: ComplianceComponent,
    canActivate: [AuthGuard]

  },
  {
    path: "register",
    component: RegistrationComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "verify/account/:email/:token",
    component: VerifyAccountComponent
  },
  {
    path: "forget-password",
    component: ForgetPasswordComponent
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
