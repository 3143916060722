import { Component } from '@angular/core';

@Component({
    selector: 'app-manage-law',
    templateUrl: './manage-law.component.html',
    styleUrls: ['./manage-law.component.css'],
    standalone: false
})
export class ManageLawComponent {

}
