<div class="col-md-6 offset-md-3 mt-5">
    <!-- <div class="alert alert-info">
        Username: test<br />
        Password: test
    </div> -->
    <div class="ng-container forget-password-logo">
        <img src="assets/billdeo_logo.png">
    </div>
    <div class="card">
        <h5 class="card-header">Premises by Billdeo - Forget Password</h5>
        <div class="card-body">
            <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()">

                <div class="form-group">
                    <label class="sr-only" for="email">Email</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="faEnvelope"></fa-icon></div>
                      </div>
                      <input type="email" formControlName="email" class="form-control py-0" name="email" id="email"  placeholder="Email" [pattern]="emailPattern"  [ngClass]="{ 'is-invalid': submitted && f.email.errors, 'is-valid': f.email.valid && f.email.touched }" >
                      <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                        <div *ngIf="f['email'].errors.required">Email is required</div>
                      </div>
                      <div *ngIf="f['email'] && f['email'].errors?.['pattern']" class="invalid-feedback">
                          Email address is not valid
                      </div>
                    </div>
                </div>

                <!-- <button type="button" (click)="goBack()" class="btn btn-primary">
                    Back <fa-icon class="l-icon" [icon]="faArrowLeft"></fa-icon>
                </button> -->
                <button [disabled]="loading" class="btn btn-success float-right my-4">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Send email <fa-icon class="l-icon" [icon]="faPaperPlane"></fa-icon>
                </button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>

            </form>
        </div>
    </div>
</div>
