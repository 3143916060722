<div class="container" >
    <h1 class="fs-2 fw-light mt-4">Property Notices</h1>
        
    <div class="form-group my-4">
        <label class="form-label" for="propertyname">Property Name</label>
        <!-- <input class="form-control col-3"  type="text" [value]="property.name"  readonly/> -->
        <input class="form-control col-3"  type="text" value="Caribbean Palms Estate" readonly/>
    </div>

          

    <table class="table table-striped table-bordered">
        <thead class="py-1">
          <th scope="col">Description</th>
          <th scope="col">Date Published</th>
          <th scope="col">Option(s)</th>
        </thead>
        <tbody>
          <tr >
           No notices to display!
          </tr>
         
        </tbody>
  
      </table>
      <div class="mt-4">
        <button (click)="goBack()" class="btn btn-primary d-print-none"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>
        <button (click)="goHome()" class="btn btn-info float-end d-print-none"><fa-icon [icon]="faHomeAlt"></fa-icon> Home</button>
      </div>
</div>
