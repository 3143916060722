import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_services';
import { AppService } from '../_services/app.service';
import { FinancialService } from '../_services/financial.service';
import { NotificationService } from '../_services/notification.service';
import { PremiseService } from '../_services/premise.service';
import { PropertyService } from '../_services/property.service';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe, Location } from '@angular/common';
import { faCircleArrowLeft, faHomeAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-laws',
    templateUrl: './laws.component.html',
    styleUrls: ['./laws.component.css'],
    standalone: false
})
export class LawsComponent {

  faCircleArrowLeft = faCircleArrowLeft;
  faHomeAlt = faHomeAlt;

  constructor(private toastr: ToastrService,
    private route: ActivatedRoute,
    private premiseService: PremiseService,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private propertyService: PropertyService,
    private financialService: FinancialService,
    private userService: UserService,
    private sendNotificationService: NotificationService,
    private _location: Location,
    private dialog: MatDialog,
    private appService: AppService,
    private router: Router) {

    }
  goBack() {
    this._location.back();
  }


  goHome(): void {
    this.router.navigate(['/home'])
  }

}
