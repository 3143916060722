<div *ngIf="action == 'noassociation'" class="container mt-4">
  <p class="alert alert-info">Your are not associated to any Premises</p>
</div>

<div *ngIf="action == 'list'" class="container mt-4">
  <div class="container" *ngIf="action == 'list'">


    <div class="form-group has-search">
        <fa-icon class="form-control-feedback" [icon]="faSearch"></fa-icon>
        <input type="text" name="search" [(ngModel)]="searchProperty" class="form-control" placeholder="Search by property name">
    </div>

    <ul class="list-group list-group-flush col-md-6">
        <li (click)="onSelectedPremise(premise)" class="fs-3 text-primary list-group-item list-group-item-action d-flex justify-content-between align-items-center" *ngFor="let premise  of premiseAssociations | filter: searchProperty ">
            <fa-icon class="l-icon" [icon]="faHouse"></fa-icon> 
            
            <span class="text-black fw-lighter">{{premise.unit_number}} </span>
            <fa-icon [icon]="faCircleArrowRight"></fa-icon>
        </li>

    </ul>

</div>
</div>

<div *ngIf="action == 'view' || action == ''" class="container mt-4">
  <form [formGroup]="viewPremisesForm">
    <div class="d-inline-flex">
      <h1 class="fs-2 fw-light mt-4">Premises Details</h1>
      
  
    </div>
    
      <div class="form-group my-4">
        <label class="form-label" for="propertyname">Property Name</label>
        <!-- <input class="form-control col-3"  type="text" [value]="property.name"  readonly/> -->
        <input class="form-control col-3"  type="text" formControlName="property_name" readonly/>
      </div>
    <div class="form-group my-4">
      <label class="form-label" for="unit_number">Lot/Apartment #</label>
      <input class="form-control col-auto" formControlName="unit_number"  type="text" readonly/>
    </div>
    <div class="form-group my-4">
      <label class="form-label" for="number_rooms">Number of Room(s)</label>
      <input class="form-control col-auto" formControlName="number_rooms"  type="text" readonly/>
    </div>
      <div class="form-group my-4">
          <label class="form-label" for="firstname">Name of Owner(s)</label>
          
          <input class="form-control col-3 my-1"  type="text" value="***** ******"  readonly/>
    
      </div>
      <div class="form-group my-4">
          <label class="form-label" for="entitlement">Unit of Entitlement</label>
          <input class="form-control col-xs-3" formControlName="unit_entitlement"  type="text" readonly/>
           
        </div>
  
      <div class="form-group my-4">
          <div class="row">
            <label class="form-label" for="firstname">Maintenance Outstanding</label>
          </div>
      
          <div class="d-inline-flex">
            <div class="col-auto input-group">
              <input class="form-control" type="text" [value]="maintenanceoutstanding | currency" readonly/>
              <button class="btn btn-outline-primary" (click)="toggleIcon('maintenance')" 
              data-bs-toggle="collapse" data-bs-target="#collapseMaintenances" 
              aria-expanded="false" aria-controls="collapseMaintenances">
                <fa-icon *ngIf="maintenanceview" [icon]="faAngleUp"></fa-icon>
                <fa-icon *ngIf="!maintenanceview" [icon]="faAngleDown"></fa-icon>
              </button>
            </div>
            <div class="col-auto mx-2">
              <button class="btn btn-primary minfobtn"><fa-icon [icon]="faInfoCircle" (click)="toggleInfo('maintenanceinfo')" ></fa-icon></button>
            </div>        
            
          </div>
          <div id="collapseMaintenances" class="accordion-collapse collapse mt-4" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <table class="table table-striped table-bordered">
                    <thead class="py-1">
                      <th scope="col">Period</th>
                      <th scope="col">Description</th>
                      <th scope="col">Amount</th>
                      <!-- <th scope="col">Option(s)</th> -->
                    </thead>
                    <tbody>
                      <tr *ngFor="let obligation of maintenances | orderBy: 'period' ">
                        <td>{{obligation.period | date: 'MMM-y':'UTC'}}</td>
                        <td>{{obligation.description}}</td>
                        <td>{{obligation.amount | currency}}</td>
                        <!-- <td><button (click)="viewObligation(obligation.id)" class="btn btn-primary">View <fa-icon [icon]="faEye"></fa-icon></button></td> -->
                      </tr>
                     
                    </tbody>
              
                  </table>
            </div>
          </div>
          <div id="maintenanceinfo" class="alert alert-info mt-1 col-md-6"><b>Maintenance Outstanding</b> shows maintenance amounts that have not been paid in full.</div>
      </div>
      <div class="form-group my-4">
          <div class="row">
            <label class="form-label" for="firstname">Miscellaneous Fees Outstanding</label>
          </div>
          
          <div class="d-inline-flex">
            <div class="col-auto input-group">
              <input class="form-control" type="text" [value]="feestotal | currency" readonly/>
              <button class="btn btn-outline-primary" (click)="toggleIcon('fees')" 
              data-bs-toggle="collapse" data-bs-target="#collapseFees" 
              aria-expanded="false" aria-controls="collapseFees">
                <fa-icon *ngIf="feesview" [icon]="faAngleUp"></fa-icon>
                <fa-icon *ngIf="!feesview" [icon]="faAngleDown"></fa-icon>
              </button>
            </div>
            <div class="col-auto mx-2">
              <button class="btn btn-primary"><fa-icon [icon]="faInfoCircle" (click)="toggleInfo('feesinfo')" ></fa-icon></button>
            </div>        
            
          </div>
          <div id="collapseFees" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFees">
            <div class="accordion-body">
                <table class="table table-striped mt-4 table-bordered">
                    <thead class="py-1">
                      <th scope="col">Period</th>
                      <th scope="col">Description</th>
                      <th scope="col">Amount</th>
                      <!-- <th scope="col">Option(s)</th> -->
                    </thead>
                    <tbody>
                      <tr *ngFor="let fee of fees">
                        <td>{{fee.period | date: 'MMM-y':'UTC '}}</td>
                        <td>{{fee.description}}</td>
                        <td>{{fee.amount | currency}}</td>
                        <!-- <td><button (click)="viewFee(fee.id)" class="btn btn-primary">View <fa-icon [icon]="faEye"></fa-icon></button></td> -->
                      </tr>
                      
                    </tbody>
              
                  </table>
            </div>
          </div>
          <div id="feesinfo" class="alert alert-info mt-1 col-md-6"><b>Miscellaneous Fees Outstanding</b> shows fees that are charged to or owed by residents.</div>
      </div>
      <div class="form-group my-4">
        <div class="row">
          <label class="form-label" for="firstname">Your Payments YTD</label>
        </div>
        
        <div class="d-inline-flex">
          <div class="col-auto input-group">
            <input class="form-control" type="text" [value]="paymnentstotal | currency" readonly/>
            <button class="btn btn-outline-primary" (click)="toggleIcon('payments')" 
              data-bs-toggle="collapse" data-bs-target="#collapsePayments" 
              aria-expanded="false" aria-controls="collapsePayments">
              <fa-icon *ngIf="paymentsview" [icon]="faAngleUp"></fa-icon>
              <fa-icon *ngIf="!paymentsview" [icon]="faAngleDown"></fa-icon>
            </button>
          </div>
          <div class="col-auto mx-2">
            <button class="btn btn-primary"><fa-icon [icon]="faInfoCircle" (click)="toggleInfo('yourpaymentsinfo')"  ></fa-icon></button>
          </div>        
          
        </div>
        <div id="collapsePayments" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
              <table class="table table-striped mt-4 table-bordered">
                  <thead>
                    <th scope="col">Period</th>
                    <th scope="col">Paid By</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Trans. Date</th>
                    <!-- <th scope="col">Option(s)</th> -->
                  </thead>
                  <tbody>
                    <tr *ngFor="let payment of payments">
                      <td>{{payment.period_date | date: 'MMM-y':'UTC'}}</td>
                      <td>{{payment.paid_by}}</td>
                      <td>{{payment.amount | currency}}</td>
                      <td>{{payment.effective_date | date :'dd-MMM-y':'UTC'}}</td>
                      <!-- <td><button (click)="viewPayment(payment.id)" class="btn btn-primary">View <fa-icon [icon]="faEye"></fa-icon></button></td> -->
                    </tr>
                    
                  </tbody>
            
                </table>
          </div>
        </div>
        <div id="yourpaymentsinfo" class="alert alert-info mt-1 col-md-6"><b>Your Payments YTD </b> show maintenance and other payments that have been made by persons related to this property.</div>
    </div>
    <div class="form-group my-4">
      <div class="row">
        <label class="form-label" for="firstname">Unverified NHT Payments YTD</label>
      </div>
      
      <div class="d-inline-flex">
        <div class="col-auto input-group">
          <input class="form-control" type="text" [value]="unverifiedpaymentstotal | currency" readonly/>
          <button class="btn btn-outline-primary" (click)="toggleIcon('unverifiedpayments')" 
            data-bs-toggle="collapse" data-bs-target="#collapseunverifiedPayments" 
            aria-expanded="false" aria-controls="collapseunverifiedPayments">
            <fa-icon *ngIf="unverifiedpaymentsview" [icon]="faAngleUp"></fa-icon>
            <fa-icon *ngIf="!unverifiedpaymentsview" [icon]="faAngleDown"></fa-icon>
          </button>
        </div>
        <div class="col-auto mx-2">
          <button class="btn btn-primary icon-bhover" (click)="toggleInfo('unverifiedpaymentsinfo')"><fa-icon [icon]="faInfoCircle"></fa-icon></button>
          
        </div>        
        
      </div>
      <div id="collapseunverifiedPayments" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
            <table class="table table-striped mt-4 table-bordered">
                <thead>
                  <th scope="col">Period</th>
                  <th scope="col">Paid By</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Trans. Date</th>
                  <!-- <th scope="col">Option(s)</th> -->
                </thead>
                <tbody>
                  <tr *ngFor="let payment of unverifiedPayments">
                    <td>{{payment.period_date | date: 'MMM-y':'UTC'}}</td>
                    <td>{{payment.paid_by}}</td>
                    <td>{{payment.amount | currency}}</td>
                    <td>{{payment.effective_date | date :'dd-MMM-y':'UTC'}}</td>
                    <!-- <td><button (click)="viewPayment(payment.id)" class="btn btn-primary">View <fa-icon [icon]="faEye"></fa-icon></button></td> -->
                  </tr>
                  
                </tbody>
          
              </table>
        </div>
      </div>
      <div id="unverifiedpaymentsinfo" class="alert alert-info mt-1 col-md-6"><b>Unverified Payments YTD</b> show payments that are made to maintenance fee because the property still has the original NHT mortage that was started in 2006. If you have completed paying your original NHT mortgage, please email <a href="mailto:caribbeanpalmsestate@gmail.com?subject='Mortgage completed?I have completed my original NHT mortgage">caribbeanpalmsestate&#64;gmail.com</a></div>
  </div>
      <!-- <div class="form-group my-4 row">
         
          <div class="accordion col-auto" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Payments YTD &nbsp;&nbsp;&nbsp;&nbsp; $500,000.00
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                      <table class="table table-striped table-bordered">
                          <thead>
                            <th scope="col">Period</th>
                            <th scope="col">Description</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Trans. Date</th>
                            <th scope="col">Payment Type</th>
                            <th scope="col">Option(s)</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>December 2022</td>
                              <td>December 2022 maintenance payment</td>
                              <td>$3,000.00</td>
                              <td>25-Dec-2022</td>
                              <td>OnlineTransfer</td> 
                              <td><button class="btn btn-primary">View <fa-icon [icon]="faEye"></fa-icon></button></td>
                            </tr>
                            <tr>
                              <td>November 2022</td>
                              <td>November 2022 maintenance payment</td>
                              <td>$2,000.00</td>
                              <td>25-Dec-2022</td>
                              <td>OnlineTransfer</td> 
                              <td><button class="btn btn-primary">View <fa-icon [icon]="faEye"></fa-icon></button></td>
                            </tr>
                          </tbody>
                    
                        </table>
                  </div>
                </div>
              </div>
              
          </div>
          <div class="col-auto">
            <button class="btn btn-primary"><fa-icon [icon]="faCirclePlus"></fa-icon></button>
          </div>
  
      </div> -->
      <div class="mt-4 d-print-none">
        <button (click)="goBack()" class="btn btn-primary"><fa-icon [icon]="faCircleArrowLeft"></fa-icon> Back</button>
        <button (click)="goHome()" class="btn btn-info float-end"><fa-icon [icon]="faHomeAlt"></fa-icon> Home</button>
      </div>
    </form>
  </div>
  
