import { CurrencyPipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Obligation } from '../_models/obligation';
import { Owner } from '../_models/owner';
import { Payment } from '../_models/payment';
import { Premise } from '../_models/premise';
import { PremiseAssociation } from '../_models/premise_association';
import { UserService } from '../_services';
import { FinancialService } from '../_services/financial.service';
import { NotificationService } from '../_services/notification.service';
import { PremiseService } from '../_services/premise.service';
import { PropertyService } from '../_services/property.service';
import { environment } from '../../environments/environment';
import {  faCircleArrowLeft, faSearch, faAngleDown, faAngleUp, faHomeAlt, faEye,
  faInfoCircle, faCircleArrowRight, faHouse } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-premises',
    templateUrl: './premises.component.html',
    styleUrls: ['./premises.component.css'],
    standalone: false
})
export class PremisesComponent {

  viewPremisesForm!: FormGroup;

  ownerData!: Owner[];

  action: any;
  searchProperty!: string;

  faCircleArrowLeft = faCircleArrowLeft;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faHomeAlt = faHomeAlt;
  faEye = faEye;
  faInfoCircle = faInfoCircle;
  faSearch = faSearch;
  faCircleArrowRight = faCircleArrowRight;
  faHouse = faHouse;

  obligations!: Obligation[];
  payments!: Payment[];
  unverifiedPayments!: Payment[];
  fees!: Obligation[];
  maintenances!: Obligation[];
  premiseData!: Premise;

  maintenanceoutstanding = 0;
  paymnentstotal = 0;
  unverifiedpaymentstotal = 0;
  feestotal = 0;

  paymentsview = false;
  unverifiedpaymentsview = false;
  feesview = false;
  maintenanceview = false;

  selectedPremise: any;
  property: any;

  premiseAssociations!: PremiseAssociation[];

  constructor(private toastr: ToastrService,
    private route: ActivatedRoute,
    private premiseService: PremiseService,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private propertyService: PropertyService,
    private financialService: FinancialService,
    private userService: UserService,
    private sendNotificationService: NotificationService,
    private _location: Location,
    private router: Router) {

    }

    get pv() { return this.viewPremisesForm.controls; }

    viewPayment(id: number) {
      console.log('Payment id:::::',id);
      this.financialService.getPayment(id).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log('Payment data fetched: ', data);
          }
          this.financialService.setPayment(data);

          this.router.navigate(['/manage-payments/view']);
        },
        error: (error) => {
          if (!environment.production) {
            console.log('Failed to fetch payment data: ', error);
          }
        }

      });



    }

    // isNotAssociated() {
    //   if (!environment.production) {
    //     console.log("No premises assocation found:::");
    //   }
    //   if (this.premiseAssociations.length == 0 || this.premiseAssociations.length < 0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }

    // isOneAssociation() {
    //   if (!environment.production) {
    //     console.log("Number premises assocation found: One :::", this.premiseAssociations.length);
    //   }
    //   if (this.premiseAssociations.length == 1) {
    //     return true;
    //   } else {
    //     return true;
    //   }

    // }

    // isMultipleAssociation() {
    //   if (!environment.production) {
    //     console.log("Number premises assocation found: Multiple :::", this.premiseAssociations.length);
    //   }
    //   if (this.premiseAssociations.length > 1) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }

    onSelectedPremise(premise: any) {

      if (!environment.production) {
        console.log('Property: Association: selected:::', premise);
      }

      this.router.navigate(['/premises/view']);


    }

    viewFee(id: number) {

      this.financialService.getObligation(id).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log('Obligation fee data fetched: ', data);
          }
          this.financialService.setFee(data);

          this.router.navigate(['/manage-fees/view']);

        },
        error: (error) => {
          if (!environment.production) {
            console.log('Failed to fetch obligation fee data: ', error);
          }
        }

      });
    }

    viewObligation(id: number) {
      this.financialService.getObligation(id).subscribe({
        next: (data) => {
          if (!environment.production) {
            console.log('Obligation data fetched: ', data);
          }
          this.financialService.setObligation(data);

          this.router.navigate(['/manage-maintenance/view']);
        },
        error: (error) => {
          if (!environment.production) {
            console.log('Failed to fetch obligation data: ', error);
          }
        }

      });

    }

    ngOnInit(): void {
      this.route.params.subscribe((params: Params) => this.action = params['action']);
      this.viewPremisesForm = this.formBuilder.group({
        property_name: ['', Validators.required],
        unit_number: ['', Validators.required],
        unit_entitlement: ['', Validators.required],
        propertyId: [''],
        number_rooms: ['', Validators.required],
        size: ['', Validators.required]
      });
      this.loadPremiseData();
    }

    loadPremiseData() {
      //Check user associations to premises

      //present with list or go directly to single premises

      this.premiseService.fetchUserAssociation().subscribe({
        next: (data) => {
          this.premiseAssociations = data;

          if (this.premiseAssociations.length == 1) {
            this.premiseService.fetchPremiseDataProtected(this.premiseAssociations[0].premiseId, this.premiseAssociations[0].propertyId).subscribe({
              next: (data) => {
                if (!environment.production) {
                  console.log('Result::: Fetch: Premise data: ', data);
                }
                this.premiseData = data;

                this.viewPremisesForm.setValue({
                  //property_name: data.property_name,
                  property_name: 'Caribbean Palms Estate',
                  unit_number: data.unit_number,
                  number_rooms: data.number_rooms,
                  unit_entitlement: data.unit_entitlement,
                  propertyId: data.propertyId,
                  size: 0
                });
                this.ownerData = data.owners || [];
                this.obligations = data.obligations || [];

                this.maintenanceoutstanding = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 1).filter(({settled}) => settled == false).reduce(function(curr, prev) {
                  return curr + parseFloat(prev.amount.toString());

                }, 0);

                this.feestotal = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 2).reduce(function(curr, prev) {
                  return curr + parseFloat(prev.amount.toString());

                }, 0);

                this.fees = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 2);

                this.maintenances = this.obligations.filter(({obligationTypeId}) => obligationTypeId == 1).filter(({settled}) => settled == false);

                this.payments = data.payments?.filter(({paid_by}) => paid_by != 'System-Adjustment') || [];

                this.unverifiedPayments = data.payments?.filter(({paid_by}) => paid_by == 'System-Adjustment') || [];

                this.unverifiedpaymentstotal = this.unverifiedPayments.reduce(function(curr, prev) {
                  return curr + parseFloat(prev.amount.toString());
                }, 0);

                this.paymnentstotal = this.payments.reduce(function(curr, prev) {
                  return curr + parseFloat(prev.amount.toString());
                }, 0);
              },
              error: (error) => {
                if (!environment.production) {
                  console.log('Error: Fetch Premise Data::: ', error);
                }
              }
            })

            this.router.navigate(['/premises/view']);

          } else if (this.premiseAssociations.length > 1) {
            this.router.navigate(['/premises/list'])
          } else {
            this.router.navigate(['/premises/noassociation'])
          }
        },
        error: (error) => {
          if (!environment.production) {

            console.log(error);
            this.toastr.error('Something went wrong. Fail to delete payment');
          }
        }
      });



    }

    toggleInfo(display: string) {
      if (!environment.production) {
        console.log('Info selected:::', display);
      }
      if (display == 'yourpaymentsinfo') {
       $('#yourpaymentsinfo').toggle();
      }

      if (display == 'maintenanceinfo') {
        $('#maintenanceinfo').toggle();
      }

      if (display == 'feesinfo') {
        $('#feesinfo').toggle();
      }

      if (display == 'unverifiedpaymentsinfo') {
        $('#unverifiedpaymentsinfo').toggle();
      }
    }

    toggleIcon(display: string) {
      if (display == 'payments') {
        this.paymentsview = !this.paymentsview;
      }

      if (display == 'unverifiedpayments') {
        this.unverifiedpaymentsview = !this.unverifiedpaymentsview;
      }

      if (display == 'maintenance') {
        this.maintenanceview = !this.maintenanceview;
      }

      if (display == 'fees') {
        this.feesview = !this.feesview;
      }
    }

    goHome(): void {
      this.router.navigate(['/home'])
    }

      goBack() {
    this._location.back();
  }


}
