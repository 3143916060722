{
  "name": "billdeo",
  "version": "1.1.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "deploy": "ng build --configuration development --base-href / && cp .htaccess /var/www/billdeo-webapp-dev",
    "deploy-prod": "ng build --configuration production --base-href / && cp .htaccess /var/www/billdeo-webapp",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.0",
    "@angular/cdk": "^19.0.3",
    "@angular/common": "^19.0.0",
    "@angular/compiler": "^19.0.0",
    "@angular/core": "^19.0.0",
    "@angular/forms": "^19.0.0",
    "@angular/material": "^19.0.3",
    "@angular/platform-browser": "^19.0.0",
    "@angular/platform-browser-dynamic": "^19.0.0",
    "@angular/router": "^19.0.0",
    "@fortawesome/angular-fontawesome": "^1.0.0",
    "@fortawesome/fontawesome-free": "^6.7.2",
    "@fortawesome/free-brands-svg-icons": "^6.7.2",
    "@fortawesome/free-regular-svg-icons": "^6.7.2",
    "@fortawesome/free-solid-svg-icons": "^6.7.2",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.7",
    "html2canvas": "^1.4.1",
    "jquery": "^3.7.1",
    "jspdf": "^2.5.2",
    "moment": "^2.30.1",
    "ng2-charts": "^7.0.0",
    "ngx-pagination": "^6.0.3",
    "ngx-search-filter": "^18.0.0",
    "ngx-toastr": "^19.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.5",
    "@angular/cli": "^19.0.5",
    "@angular/compiler-cli": "^19.0.0",
    "@types/jasmine": "~5.1.0",
    "@types/jquery": "^3.5.32",
    "@types/node": "^22.10.2",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.6.2"
  }
}
